<template>
  <v-row 
    v-if="isPF"
    no-gutters
    class="box pt-0"
  >
    <v-col 
      cols="12"
      sm="6"
    >
      <legend>O cliente é cidadão americano?</legend>
      <v-radio-group
        data-test="rd-cidadao-americano"
        row
        class="py-0"
        v-model="localCustomer.usPerson"
        :rules="[validationIsRequiredRadioBtn]"
      >
        <v-radio
          data-test="rd-sim"
          :value="true"
          label="Sim"
        />
        <v-radio
          data-test="rd-nao"
          :value="false"
          label="Não"
        />
      </v-radio-group>

      <v-row v-if="localCustomer.usPerson">
        <v-col>
          <v-text-field
            data-test="txt-itin"
            v-model="localCustomer.additional.individualTaxpayerIdentificationNumber"
            outlined
            dense
            label="Número ITIN"
            :rules="[validationIsRequiredField]"
            v-mask="'###-##-####'"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Models
import { CustomerModel } from '@/model/customer-model';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";

export default {
  name: 'PFCustomerUSPerson',

  mixins: [mixinValidationRules],

  model: { 
    prop: 'customer',
    event: 'onChange',
  },

  props: {
    customer: {
      type: CustomerModel,
      required: true
    },
  },

  computed: {
    localCustomer: {
      get() {
        return this.customer;
      },
      set(valor) {
        this.$emit('onChange', valor);
      }
    },

    isPF() {
      return this.customer.registerType?.id !== REGISTER_TYPE.LEGAL_PERSON.id;
    },
  },
};
</script>