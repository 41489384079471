<template>
  <v-row>
    <template v-if="operation.manualRate">
      <v-col
        cols="12"
        md="12"
        align="right"
        class="pb-0"
      >
        <div class="operation-wrapper">
          <MoneyField
            data-test="moneyfield-cambio-manual"
            label="Câmbio comercial (SPOT)"
            precision="5"
            v-model="operation.currencyQuotation"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        md="12"
        align="right"
        class="pb-0"
        v-if="
          (operation.bank?.useUsdLimit || operation.useUsdExchangeContract) &&
          operation.currency?.code != 'USD'
        "
      >
        <div class="operation-wrapper">
          <MoneyField
            data-test="moneyfield-dolar-paridade"
            label="Dólar comercial (para paridade)"
            precision="5"
            @change="$emit('update:usdValue', $event)"
          />
        </div>
      </v-col>
    </template>
    <template v-else>
      <v-col
        cols="12"
        md="12"
        align="right"
        class="pb-0"
      >
        <div class="operation-wrapper">
          <span
            class="operation-title"
            data-test="titulo-cambio-spot"
          >
            Câmbio comercial (SPOT)
          </span>
          <span
            class="operation-value"
            data-test="valor-cambio-spot"
          >
            {{
              operationProp.currencyQuotation ||
              0 | formatCurrency(operationProp.currency.precision)
            }}
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="12"
        align="right"
        class="pt-0"
        v-if="remainingTimeCurrencyUpdate"
      >
        <div class="operation-wrapper">
          <span
            class="timer"
            data-test="timer-atualizacao"
          >
            {{ remainingTimeCurrencyUpdate }}
          </span>
        </div>
      </v-col>
    </template>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-iof-percentual"
        >
          IOF(%)
        </span>
        <span
          class="operation-value"
          data-test="valor-iof-percentual"
        >
          {{ operationProp.iof || 0 | formatNumber }} %
        </span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-valor-iof"
        >
          Valor do IOF
        </span>
        <span
          class="operation-value"
          data-test="valor-valor-iof"
        >
          {{ operationProp.iofValue || 0 | formatCurrency(2) }}
        </span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-ir-percentual"
        >
          IR(%)
        </span>
        <span
          class="operation-value"
          data-test="valor-ir-percentual"
        >
          {{ operationProp.ir || 0 | formatNumber }} %
        </span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-valor-ir"
        >
          Valor do IR
        </span>
        <span
          class="operation-value"
          data-test="valor-valor-ir"
        >
          {{ operationProp.irValue || 0 | formatCurrency(2) }}
        </span>
      </div>
    </v-col>
    <template v-if="operationProp.cide">
      <v-col
        cols="12"
        md="12"
        align="right"
      >
        <div class="operation-wrapper">
          <span
            class="operation-title"
            data-test="titulo-cide-percentual"
          >
            CIDE(%)
          </span>
          <span
            class="operation-value"
            data-test="valor-cide-percentual"
          >
            {{ operationProp.cide || 0 | formatNumber }} %
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="12"
        align="right"
      >
        <div class="operation-wrapper">
          <span
            class="operation-title"
            data-test="titulo-valor-cide"
          >
            Valor do CIDE
          </span>
          <span
            class="operation-value"
            data-test="valor-valor-cide"
          >
            {{ operationProp.cideValue || 0 | formatCurrency(2) }}
          </span>
        </div>
      </v-col>
    </template>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-tarifa"
        >
          Tarifa{{
            operationProp.useUsdExchangeContract
              ? `(${formatUSD(operationProp.exchangeContractCost)})`
              : ``
          }}
        </span>
        <span
          class="operation-value"
          data-test="valor-tarifa"
        >
          {{ operationProp.exchangeContractCostBrl || 0 | formatCurrency }}
        </span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-taxa-cliente"
        >
          Taxa do cliente
        </span>
        <span
          class="operation-value"
          data-test="valor-taxa-cliente"
        >
          {{ operationProp.customerRate || 0 | formatCurrency(operationProp.currency.precision) }}
        </span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-vet"
        >
          VET(Valor efetivo total)
        </span>
        <span
          class="operation-value"
          data-test="valor-vet"
        >
          {{ operationProp.vet || 0 | formatCurrency(operationProp.currency.precision) }}
        </span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <div class="operation-wrapper">
        <span
          class="operation-title"
          data-test="titulo-total-operacao"
        >
          Total da operação
        </span>
        <span
          class="operation-value"
          data-test="valor-total-operacao"
        >
          {{ operationProp.totalValue || 0 | formatCurrency(2) }}
        </span>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
    >
      <v-btn
        data-test="botao-copiar-mensagem"
        class="btn-secondary mr-2"
        block
        :disabled="!operationProp.currency?.id"
        @click="copyCustomerMessageToClipboard"
        text
      >
        <i
          class="far fa-copy"
          aria-hidden="true"
        />
        Copiar mensagem do cliente
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
// Utils
import operationMessagesTemplate from '@/utils/operationMessagesTemplate';
import mixinMessage from '@/mixin/mixin-message';
import numberUtils from '../../../../common/utils/number';

import MoneyField from '@/components/comum/MoneyField';

export default {
  name: 'OperationPreviewExchange',
  mixins: [mixinMessage],
  components: { MoneyField },
  data() {
    return {
      operation: {}
    };
  },
  model: {
    prop: 'operationProp',
    event: 'onChange'
  },
  props: {
    operationProp: {
      type: Object,
      default: () => {}
    },
    remainingTimeCurrencyUpdate: {
      type: String,
      default: ''
    },
    usdValue: {
      type: [Number, String],
      default: null
    }
  },
  mounted() {
    this.operation = this.operationProp;
  },
  watch: {
    operationProp() {
      this.operation = this.operationProp;
    }
  },
  filters: {
    formatCurrency(value, precison, currency) {
      currency = currency || 'BRL';

      return numberUtils.formatCurrency(currency, value, precison);
    },
    formatNumber(value) {
      return numberUtils.formatNumber(value);
    }
  },
  methods: {
    copyCustomerMessageToClipboard() {
      navigator.clipboard.writeText(
        operationMessagesTemplate.getCustomerMessage(this.operationProp)
      );
      this.sendMessage('A mensagem do cliente foi copiada em seu CTRL + C', 'success');
    },

    formatUSD(value) {
      return numberUtils.formatCurrency('USD', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.operation-title {
  text-align: left;
  font-size: 0.9rem;
}

.operation-value {
  font-size: 1.3rem;
  text-align: right;
  color: var(--v-primary-base);
}

.operation-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timer {
  color: red;
}
</style>
