<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Trabalho</h1>
        <v-divider class="py-3" />
        <PFCustomerWork
          :is-complete-remittance="isCompleteRemittance"
          v-model="customer"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PFCustomerWork from './PF/work/PFCustomerWork.vue';

// Constants
import { REGISTER_TYPE } from '@/constants/general-constants.js';

export default {
  name: 'CustomerWork',
  components: { PFCustomerWork },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => ({})
    },
    isCompleteRemittance: {
      type: Boolean
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  },
  data() {
    return {
      customer: this.customerProp,
      listOfRegisterTypes: Object.values(REGISTER_TYPE)
    };
  }
};
</script>

<style></style>
