<template>
  <v-row
    class="py-2"
    data-test="row-pj-customer-contacts"
  >
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-checkbox
        data-test="chk-tel-internacional"
        label="Tel. internacional"
        v-model="customerContactData.internationalLandline"
        dense
      />
    </v-col>
    <v-col
      class="py-0 pr-0"
      cols="12"
      md="1"
      v-if="customerContactData.internationalLandline"
    >
      <v-text-field
        data-test="txt-ddi-fixo"
        outlined
        dense
        placeholder="+000"
        v-mask="'+###'"
        inputmode="decimal"
        label="DDI"
        maxlength="3"
        v-model="customerContactData.ddiLandline"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      :lg="customerContactData.internationalLandline ? 3 : 4"
    >
      <PhoneField
        data-test="txt-telefone-fixo"
        :is-international-phone="customerContactData.internationalLandline"
        is-landline
        v-model="customerContactData.landline"
        label="Telefone Fixo"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-checkbox
        data-test="chk-cel-internacional"
        label="Cel. internacional"
        v-model="customerContactData.internationalCellphone"
        dense
      />
    </v-col>
    <v-col
      class="py-0 pr-0"
      cols="12"
      md="1"
      v-if="customerContactData.internationalCellphone"
    >
      <v-text-field
        data-test="txt-ddi-celular"
        outlined
        dense
        placeholder="+000"
        v-mask="'+###'"
        inputmode="decimal"
        label="DDI"
        maxlength="3"
        :rules="[validationIsRequiredField]"
        v-model="customerContactData.ddiCellphone"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      :lg="customerContactData.internationalCellphone ? 3 : 4"
    >
      <PhoneField
        data-test="txt-celular-empresa"
        :is-international-phone="customerContactData.internationalCellphone"
        v-model="customerContactData.cellphone"
        label="Celular"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="3"
    >
      <MailField
        data-test="txt-email-empresa"
        label="E-mail"
        :required="true"
        v-model="customerContactData.email"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="3"
    >
      <MailField
        data-test="txt-email-secundario"
        label="E-mail secundário"
        :required="true"
        v-model="customerContactData.secondEmail"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="5"
    >
      <v-text-field
        data-test="txt-website"
        v-model="customerContactData.website"
        outlined
        label="Website"
        autocomplete="off"
        dense
        counter="100"
        maxlength="100"
      />
    </v-col>
  </v-row>
</template>

<script>
// Components
import PhoneField from '@/components/comum/PhoneField.vue';
import MailField from '@/components/comum/MailField.vue';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

export default {
  name: 'PJCustomerContacts',
  mixins: [mixinValidationRules],
  components: { PhoneField, MailField },
  model: {
    prop: 'model',
    event: 'onChange'
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    customerContactData() {
      this.$emit('onChange', this.customerContactData);
    }
  },
  data() {
    return {
      customerContactData: this.model
    };
  }
};
</script>

<style></style>
