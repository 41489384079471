<template>
  <v-row 
    v-if="isPF"
    no-gutters
    class="box pt-0"
  >
    <v-col 
      cols="12"
      sm="6"
    >
      <legend>O cliente possui domicílio fiscal no exterior - FATCA ou CRS?</legend>
      <small>FATCA: Foreign Account Tax Compliance Act. CRS: Common Reporting Standard.</small>

      <v-radio-group
        data-test="rd-possui-residencia-exterior"
        row
        class="py-0"
        v-model="localCustomer.taxDomicileAbroad"
        :rules="[validationIsRequiredRadioBtn]"
      >
        <v-radio
          data-test="rd-sim"
          :value="true"
          label="Sim"
        />
        <v-radio
          data-test="rd-nao"
          :value="false"
          label="Não"
        />
      </v-radio-group>

      <v-row v-if="localCustomer.taxDomicileAbroad">
        <v-col>
          <CountryField
            data-test="slc-pais-domicilio"
            label="País"
            v-model="localCustomer.additional.taxDomicileAbroadCountry"
            :rules="[validationIsRequiredAutocompleteText]"
          />
        </v-col>
        <v-col>
          <v-text-field
            data-test="txt-numero-identificacao"
            v-model="localCustomer.additional.taxDomicileAbroadIdentification"
            outlined
            dense
            label="Número de itentificação"
            :rules="[validationIsRequiredField]"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Models
import { CustomerModel } from '@/model/customer-model';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";

// Components
import CountryField from "@/components/comum/CountryField.vue";

export default {
  name: 'PFCustomerTaxDomicileAbroad',

  mixins: [mixinValidationRules],

  components: {
    CountryField
  },

  model: { 
    prop: 'customer',
    event: 'onChange',
  },

  props: {
    customer: {
      type: CustomerModel,
      required: true
    },
  },

  computed: {
    localCustomer: {
      get() {
        return this.customer;
      },
      set(valor) {
        this.$emit('onChange', valor);
      }
    },

    isPF() {
      return this.customer.registerType?.id !== REGISTER_TYPE.LEGAL_PERSON.id;
    },
  },
};
</script>