<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Dados bancários no exterior</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            class="pb-2"
            cols="12"
          >
            <legend>Deseja cadastrar uma conta no exterior?</legend>
            <v-radio-group
              data-test="rd-conta-exterior"
              row
              class="py-0"
              v-model="correspondent.addInternationalBank"
            >
              <v-radio
                data-test="rd-sim"
                :value="true"
                label="Sim"
              />
              <v-radio
                data-test="rd-nao"
                :value="false"
                label="Não"
              />
            </v-radio-group>
          </v-col>
          <v-col
            v-if="correspondent.addInternationalBank"
            cols="12"
            class="py-0"
            :key="key"
          >
            <v-row
              v-for="(bank, index) in correspondent.internationalBanks"
              :key="index"
              class="card-border"
            >
              <v-col
                cols="12"
                md="11"
              >
                <InternationalBankComponent
                  v-model="correspondent.internationalBanks[index]"
                  required
                  hide-intermediary
                />
              </v-col>
              <v-col
                cols="12"
                md="1"
                v-if="correspondent.internationalBanks.length >= 2"
                class="py-0"
              >
                <v-btn
                  data-test="btn-remover-banco-internacional"
                  icon
                  class="float-right"
                  @click="removeBank(index)"
                  text
                >
                  <i class="far fa-trash" />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <template v-if="correspondent.addInternationalBank">
          <v-divider data-test="divider" class="mt-3" />
          <v-row
            class="py-0"
            data-test="row-adicionar-banco"
          >
            <v-col class="pt-5">
              <v-btn
                data-test="btn-adicionar-banco"
                class="btn-secondary float-right"
                @click="insertBank"
                text
              >
                <i class="far fa-plus" /> Adicionar outro
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

// Components
import InternationalBankComponent from "@/components/comum/InternationalBankComponent";

// Model
import CorbanInternationalBankModel from '@/model/corban-international-bank-model';

export default {
  name: "CorrespondentInternationalBank",
  components: { InternationalBankComponent },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: {
      type: Object,
    },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
      key: 0,
    };
  },
  watch: {
    correspondent() {
      this.$emit("onChange", this.correspondent);
    },
  },
  mounted() {
    if (this.correspondent.internationalBanks.length == 0) {
      this.correspondent.internationalBanks = [new CorbanInternationalBankModel()];
    }
  },
  methods: {
    insertBank() {
      this.correspondent.internationalBanks.push(new CorbanInternationalBankModel());
    },
    removeBank(index) {
      if (this.correspondent.internationalBanks.length > 1) {
        this.correspondent.internationalBanks.splice(index, 1);
        this.key++;
      }
    }
  }
};
</script>

<style></style>
