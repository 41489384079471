import { render, staticRenderFns } from "./CommissionsControl.vue?vue&type=template&id=bb0d0668&scoped=true"
import script from "./CommissionsControl.vue?vue&type=script&lang=js"
export * from "./CommissionsControl.vue?vue&type=script&lang=js"
import style0 from "./CommissionsControl.vue?vue&type=style&index=0&id=bb0d0668&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb0d0668",
  null
  
)

export default component.exports