<template>
  <v-row 
    no-gutters
    class="box"
  >
    <v-col cols="12">
      <v-row no-gutters>
        <v-col>
          <h1 class="titulo">Pré venda</h1>
          <v-divider class="pb-2" />
        </v-col>
      </v-row>
        
      <v-row no-gutters>
        <v-col cols="12">
          <v-card>
            <v-col
              cols="12"
              class="pa-0 pt-1"
            >
              <v-tabs v-model="tab">
                <v-tab
                  key="operacoes-internas"
                  v-administrative-access
                >
                  <span>Operações internas</span>
                </v-tab>
                <v-tab
                  key="operacoes-externas"
                  v-administrative-access
                >
                  <span>Operações externas</span>
                </v-tab>
                <v-tab
                  key="operacoes-processamento"
                  v-corban-access
                >
                  <span>Operações em processamento</span>
                </v-tab>
              </v-tabs>
            </v-col>

            <v-tabs-items v-model="tab">
              <v-tab-item
                eager
                key="operacoes-internas"
              >
                <v-col
                  cols="12"
                  class="pt-2 pt-md-0"
                >
                  <OpenOperationsReport :operations="internalOperations" />
                </v-col>
              </v-tab-item>

              <v-tab-item
                eager
                key="operacoes-externas"
              >
                <OpenOperationsReport :operations="externalOperations" />
                <v-col
                  cols="12"
                  md="12"
                >
                  <OpenOperationsReport :operations="externalOperations" />
                </v-col>
              </v-tab-item>

              <v-tab-item
                eager
                key="operacoes-processamento"
              >
                <v-col
                  cols="12"
                  md="12"
                >
                  <OpenOperationsReport :operations="externalOperations" />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import OpenOperationsReport from './OpenOperationsReport.vue';
import { TYPES } from '../../../../common/constants/generic/sketches';
import { PROFILES } from '../../../../common/constants/generic/types';
import SketchFilterModel from '@/model/general/sketch-filters-model';
import OperationModel from '@/model/operation-model';
import SketchApi from '@/api/general/sketch-api';
import mixinMessage from '@/mixin/mixin-message';

export default {
  name: 'PreSaleReport',

  components: {
    OpenOperationsReport
  },

  mixins: [mixinMessage],

  inject: ['appData'],

  data() {
    return {
      tab: null,
      sketchApi: new SketchApi(this.appData.currentUser),
      internalOperations: [],
      externalOperations: []
    };
  },

  mounted() {
    this.getOpenOperation();

    if (this.appData.currentUser.profileId == PROFILES.CORBAN.id) {
      this.tab = 2;
    }
  },

  methods: {
    getOpenOperation() {
      let filters = new SketchFilterModel({
        typeId: TYPES.OPERATION.id,
        startDate: new Date(),
        endDate: new Date()
      });

      this.$eventBus.$emit('loading', true);

      this.sketchApi
        .find(filters)
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const sketch = response.data[i];
            let operation = new OperationModel(JSON.parse(sketch.content));

            operation.sketchId = sketch.id;
            operation.updatedAt = sketch.updatedAt;
            operation.counterCurrencyQuotation = 10;

            if (sketch.isUserCorban) {
              this.externalOperations.push(operation);
            } else {
              this.internalOperations.push(operation);
            }
          }
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          }

          this.internalOperations = [];
          this.externalOperations = [];
        })
        .finally(() => {
          this.$eventBus.$emit('loading', false);
        });
    }
  }
};
</script>

<style scoped>
::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  display: none !important;
}
</style>
