<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        text
        color="primary"
        @click="action"
        v-bind="attrs"
        v-on="on"
      >
        <i :class="icon" />
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "IconButtonWithTooltip",

  props: {
    tooltip: { type: String, required: true },
    icon: { type: String, default: 'far fa-pencil' },
    action: { type: Function, required: true}
  }
};
</script>