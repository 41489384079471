import { render, staticRenderFns } from "./CorrespondentSpreads.vue?vue&type=template&id=059dfa4b"
import script from "./CorrespondentSpreads.vue?vue&type=script&lang=js"
export * from "./CorrespondentSpreads.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports