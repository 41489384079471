<template>
  <div class="py-2">
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="4"
      >
        <v-text-field
          data-test="txt-profissao"
          v-model="customer.occupation.profession"
          outlined
          dense
          label="Profissão"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0 pr-0"
        cols="12"
        md="4"
      >
        <v-text-field
          data-test="txt-empresa-atual"
          v-model="customer.occupation.company"
          outlined
          dense
          label="Empresa atual"
        />
      </v-col>
      <v-col
        v-if="isCompleteRemittance"
        cols="12"
        md="4"
        class="py-0 pr-0"
      >
        <v-text-field
          data-test="txt-cargo"
          v-model="customer.occupation.position"
          outlined
          dense
          label="Cargo"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Constants
import { REGISTER_TYPE } from '@/constants/general-constants';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';

export default {
  name: 'PFCustomerWork',
  mixins: [mixinValidationRules],
  components: {},
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object,
      default: () => ({})
    },
    isCompleteRemittance: {
      type: Boolean
    }
  },
  watch: {
    customer() {
      this.$emit('onChange', this.customer);
    }
  },
  computed: {},
  data() {
    return {
      customer: this.customerProp,
      listOfRegisterTypes: Object.values(REGISTER_TYPE)
    };
  }
};
</script>

<style></style>
