<template>
  <div class="ma-5">
    <ConfirmationWindow
      v-model="openConfirmationWindow"
      :callback-primary="confirmationWindowCallback"
      :message="confimationWindowMessage"
    />

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row
      v-else
      no-gutters
    >
      <v-col
        cols="12"
        md="12"
        class="pb-2"
      >
        <h1 class="titulo">Controle de comissões</h1>
        <v-divider />
      </v-col>

      <v-col
        cols="12"
        md="9"
        sm="8"
        class="pb-2"
      >
        <v-btn
          class="btn-primary ml-0"
          text
          @click="creditOperations"
        >
          <i class="far fa-plus" />
          Creditar comissões do mês anterior
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="4"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          class="pt-0"
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio">
          <v-data-table
            :headers="columns"
            :items="correspondents"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="paginationDefault"
            single-expand
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            height="calc(100dvh - 310px)"
            fixed-header
          >
            <template #[`item.id`]="{ item }">
              <td width="100px">
                {{ item.id }}
              </td>
            </template>
            <template #[`item.name`]="{ item }">
              <td>
                {{ item.name }}
              </td>
            </template>

            <template #expanded-item="{ headers }">
              <td :colspan="headers.length">
                <v-card class="card-cambio my-5">
                  <v-data-table
                    :headers="expandedColumns"
                    :items="accounts"
                    disable-pagination
                    disable-filtering
                    hide-default-footer
                    :items-per-page="-1"
                    :loading="expandedIsLoading"
                    :hide-default-header="expandedIsLoading"
                    loading-text="Carregando informações das contas"
                    sort-by="agent.type"
                    sort-asc
                  >
                    <template #[`item.balance`]="{ item }">
                      {{ item.balance | formatCurrency }}
                    </template>

                    <template #[`item.lastPayment`]="{ item }">
                      {{ item.lastPayment | date }}
                    </template>

                    <template #[`item.lastCredit`]="{ item }">
                      {{ item.lastCredit | date }}
                    </template>

                    <template #[`item.actions`]="{ item }">
                      <template>
                        <v-menu
                          offset-y
                          left
                        >
                          <template #activator="{ on: menu }">
                            <v-tooltip top>
                              <template #activator="{ on: tooltip, attrs }">
                                <v-btn
                                  icon
                                  text
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                  @click.stop
                                >
                                  <i class="far fa-ellipsis-v" />
                                </v-btn>
                              </template>
                              <span>Opções</span>
                            </v-tooltip>
                          </template>

                          <v-list>
                            <v-list-item @click="openModalCreditStatement(item)">
                              <i class="far fa-file-export pr-2 menu-buttons" />
                              <v-list-item-title>Extrato de Movimentação</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="openModalCreatePaymentOrder(item)">
                              <i class="far fa-hand-holding-usd pr-2 menu-buttons" />
                              <v-list-item-title>Adicionar Pagamento</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </template>
                  </v-data-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      v-model="modalCreditStatement"
      max-width="75vw"
    >
      <v-card>
        <v-card-title>
          <h3>Extrato de Comissões - {{ this.selectedAccount.agent.name }}</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3" />
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="2"
              sm="3"
            >
              <Datepicker
                v-model="startDate"
                label="Data Inicial"
                v-disabled-icon-focus
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="3"
            >
              <Datepicker
                v-model="endDate"
                label="Data Final"
                v-disabled-icon-focus
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="2"
            >
              <v-btn
                text
                class="btn-secondary"
                @click="applyFilterByDate"
              >
                Aplicar filtro
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="2"
              sm="6"
            >
              <label>Saldo Disponível</label>
              <h2 class="remaining-value">{{ this.selectedAccount.balance | formatCurrency }}</h2>
            </v-col>
          </v-row>
          <v-divider class="py-4" />
          <v-card class="card-cambio">
            <v-data-table
              dense
              :headers="columnsStatement"
              :items="statementItems"
              sort-by="date"
              no-data-text="Não há informações"
              sort-desc
              :items-per-page="paginationDefault"
            >
              <template #[`item.date`]="{ item }">
                {{ item.date | date }}
              </template>
              <template #[`item.agentValue`]="{ item }">
                <template v-if="item.agentValue">
                  {{ item.agentValue | formatCurrency }}
                </template>
              </template>
              <template #[`item.withdrawalValue`]="{ item }">
                <template v-if="item.withdrawalValue">
                  {{ item.withdrawalValue | formatCurrency }}
                </template>
              </template>

              <template #[`item.nf`]="{ item }">
                <template v-if="item.withdrawalValue">
                  <DataTableDocumentInput
                    download-file-name="NF"
                    :callback-add-document="addDocument"
                    :callback-download-document="downloadDocument"
                    v-model="item.nf"
                  />
                </template>
              </template>

              <template #[`item.proof`]="{ item }">
                <template v-if="item.withdrawalValue">
                  <DataTableDocumentInput
                    download-file-name="Comprovante"
                    :callback-add-document="addDocument"
                    :callback-download-document="downloadDocument"
                    v-model="item.proof"
                  />
                </template>
              </template>
            </v-data-table>
          </v-card>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="6"
            >
              <label>Total do Período</label>
              <h2 class="limit-value">{{ totalAmount || 0 | formatCurrency }}</h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-tertiary ma-2"
            @click="closeModalCreditStatement"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create Payment Order -->
    <v-dialog
      persistent
      v-model="modalCreatePaymentOrder"
      max-width="30vw"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">
            Criar Ordem de pagamento - {{ this.selectedAccount.agent.name }}
          </h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-payment-order"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <MoneyField
                      label="Valor Pago"
                      prefix="R$ "
                      v-model="paymentOrder.value"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-file-input
                      label="Nota Fiscal"
                      outlined
                      dense
                      prepend-icon="fa-paperclip"
                      v-model="paymentOrderDocumentNf"
                      v-disabled-icon-focus
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-file-input
                      label="Comprovante de Pagamento"
                      outlined
                      dense
                      prepend-icon="fa-paperclip"
                      v-model="paymentOrderDocumentProof"
                      v-disabled-icon-focus
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="createPaymentOrder"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="modalCreatePaymentOrder = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Apis
import CorbanApi from '@/api/correspondent/correspondent-api';
import OperationApi from '@/api/operation/operation-api';
import apiCommissionAccount from '@/api/correspondent/agent-commission-account-api';
import apiPaymentOrder from '@/api/operation/payment-order-api';
import apiPaymentOrderDocument from '@/api/operation/payment-order-documents-api';
import TriggerApi from '@/api/generic/trigger-api';

// Models
import CorrespondentModel from '@/model/correspondent-model';
import OperationFiltersModel from '@/model/operation-filters-model';
import AgentCommissionAccountModel from '@/model/agent-commission-account-model';
import OperationModel from '@/model/operation-model';
import CommissionStatementModel from '@/model/commission-statement-model';
import CommissionPaymentOrderModel from '@/model/commission-payment-order-model';
import CommissionPaymentOrderDocumentModel from '@/model/commission-payment-order-document-model';
import TriggerModel from '@/model/generic/trigger-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Utils
import dateUtils from '../../../../common/utils/date';
import utilsStorage from '@/utils/storage';
import numberUtils from '../../../../common/utils/number';

// Tracking
import mixpanel from 'mixpanel-browser';

// Components
import Datepicker from '@/components/comum/Datepicker.vue';
import MoneyField from '@/components/comum/MoneyField';
import DataTableDocumentInput from '@/components/comum/DataTableDocumentInput.vue';
import ConfirmationWindow from '@/components/comum/ConfirmationWindow';

// Constants
import { COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE } from '@/constants/general-constants';
import { SEND_EMAIL_TRIGGER_CODES } from '../../../../common/constants/generic/triggers';
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: 'CommissionsControl',
  mixins: [mixinMessage, mixinValidationRules],
  components: {
    Datepicker,
    MoneyField,
    DataTableDocumentInput,
    ConfirmationWindow
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatCurrency(value) {
      return numberUtils.formatCurrency('BRL', value);
    }
  },
  inject: ['appData'],
  data() {
    return {
      loading: true,
      search: '',
      user: utilsStorage.getUserOnStorage(),
      correspondents: [],
      columns: [
        {
          text: 'Código',
          value: 'id',
          align: 'left',
          sortable: true
        },
        { text: 'Nome', value: 'name', align: 'left', sortable: true }
      ],
      accounts: [],
      expanded: [],
      expandedIsLoading: false,
      expandedColumns: [
        { text: 'Código', value: 'agent.id', align: 'left' },
        { text: 'Nome', value: 'agent.name', align: 'left' },
        { text: 'Tipo', value: 'agent.type', align: 'left' },
        { text: 'Saldo', value: 'balance', align: 'center' },
        { text: 'Último Pagamento', value: 'lastPayment', align: 'center' },
        { text: 'Data de Crédito', value: 'lastCredit', align: 'center' },
        { text: '', value: 'actions', align: 'center' }
      ],
      indicatorOperations: [],
      agentOperations: [],
      paymentOrders: [],
      startDate: new Date(),
      endDate: new Date(),
      selectedAccount: new AgentCommissionAccountModel(),
      modalCreditStatement: false,
      columnsStatement: [
        { text: 'Código Operação', value: 'operationId', align: 'right' },
        { text: 'Data', value: 'date', align: 'center', sortable: true },
        { text: 'Comissão', value: 'agentValue', align: 'right' },
        { text: 'Saques', value: 'withdrawalValue', align: 'right' },
        { text: 'NF', value: 'nf', align: 'center' },
        { text: 'Comprovante', value: 'proof', align: 'center' },
        { text: '', value: 'actions', align: 'right' }
      ],
      paymentOrder: new CommissionPaymentOrderModel(),
      modalCreatePaymentOrder: false,
      paymentOrderDocumentNf: undefined,
      paymentOrderDocumentProof: undefined,
      openConfirmationWindow: false,
      confirmationWindowCallback: () => {},
      confimationWindowMessage: '',
      corbanApi: new CorbanApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT,
      operationApi: new OperationApi(this.appData.currentUser),
    };
  },
  mounted() {
    this.getCorrespondentsList();
    mixpanel.track('page_view', {
      name_of_page_viewed: 'commission_control',
      user_id: this.user.id
    });
  },

  computed: {
    statementItems() {
      let items = [];

      this.indicatorOperations.forEach((operation) => {
        let statementItem = new CommissionStatementModel({
          date: operation.effectiveOperationDate,
          operationId: operation.id,
          indicatorValue: operation.administrative.indicatorValue
        });
        items.push(statementItem);
      });

      this.agentOperations.forEach((operation) => {
        let statementItem = new CommissionStatementModel({
          date: operation.effectiveOperationDate,
          operationId: operation.id,
          agentValue: operation.administrative.agentValue
        });
        items.push(statementItem);
      });

      this.paymentOrders.forEach((order) => {
        let nf =
          order.documents.find(
            (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id
          ) ||
          new CommissionPaymentOrderDocumentModel({
            paymentOrderId: order.id,
            documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF
          });
        let proof =
          order.documents.find(
            (d) => d.documentType.id == COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF.id
          ) ||
          new CommissionPaymentOrderDocumentModel({
            paymentOrderId: order.id,
            documentType: COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF
          });

        let statementItem = new CommissionStatementModel({
          date: order.paymentDate || 'Pagamento Pendente',
          withdrawalValue: order.value,
          nf,
          proof
        });
        items.push(statementItem);
      });

      return items;
    },

    totalAmount() {
      let total = 0;

      this.indicatorOperations.forEach((operation) => {
        total += +operation.administrative.indicatorValue;
      });

      this.agentOperations.forEach((operation) => {
        total += +operation.administrative.agentValue;
      });

      return total;
    }
  },

  watch: {
    expanded() {
      this.accounts = [];

      if (!this.expanded.length) {
        return;
      }

      this.findRelatedAccount();
    }
  },

  methods: {
    getCorrespondentsList() {
      this.corbanApi
        .findIndicators()
        .then((response) => {
          this.correspondents = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    creditOperations() {
      this.loading = true;

      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let lastDayLastMonth = currentDate.setDate(0);
      let firstDayLastMonth = new Date(lastDayLastMonth).setDate(1);

      let filters = new OperationFiltersModel({
        statusList: [OPERATION_WORKFLOW.LIQUIDADA.id],
        startDate: new Date(firstDayLastMonth),
        endDate: new Date(lastDayLastMonth)
      });

      this.operationApi
        .creditOperationCommissions(filters)
        .then(() => {
          this.getCorrespondentsList();
          this.expanded = [];
          this.confirmationWindowCallback = this.callbackSendCommissionReports;
          this.confimationWindowMessage = 'Deseja enviar o relatório de comissões aos corbans?';
          this.openConfirmationWindow = true;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openModalCreditStatement(commissionAccount) {
      this.startDate = dateUtils.firstDayOfLastMonth();
      this.endDate = dateUtils.lastDayOfLastMonth();
      this.selectedAccount = commissionAccount;

      this.getStatementData();

      this.modalCreditStatement = true;
    },

    closeModalCreditStatement() {
      this.modalCreditStatement = false;
    },

    applyFilterByDate() {
      this.getStatementData();
    },

    getStatementData() {
      this.getAgentOperations();
      this.getIndicatorOperations();
      this.getPaymentOrders();
    },

    getAgentOperations() {
      let operationFilter = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate,
        agentId: this.selectedAccount.agent.id,
        statusList: [OPERATION_WORKFLOW.LIQUIDADA.id]
      });

      this.operationApi
        .getOperations(operationFilter)
        .then((response) => {
          this.agentOperations = response.data.map((o) => new OperationModel(o));
        })
        .catch(() => {
          this.agentOperations = [];
        });
    },

    getIndicatorOperations() {
      let operationFilter = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate,
        indicatorId: this.selectedAccount.agent.id,
        statusList: [OPERATION_WORKFLOW.LIQUIDADA.id]
      });

      this.operationApi
        .getOperations(operationFilter)
        .then((response) => {
          this.indicatorOperations = response.data.map((o) => new OperationModel(o));
        })
        .catch(() => {
          this.indicatorOperations = [];
        });
    },

    getPaymentOrders() {
      let filters = new OperationFiltersModel({
        startDate: this.startDate,
        endDate: this.endDate,
        agentId: this.selectedAccount.agent.id
      });

      apiPaymentOrder
        .find(filters)
        .then((response) => {
          this.paymentOrders = response.data.map((o) => new CommissionPaymentOrderModel(o));
        })
        .catch(() => {
          this.paymentOrders = [];
        });
    },

    findRelatedAccount() {
      let item = this.expanded[0];

      this.expandedIsLoading = true;
      apiCommissionAccount
        .findRelated(item.id)
        .then((response) => {
          this.accounts = response.data.map((a) => {
            a = new AgentCommissionAccountModel(a);
            a.agent.type = a.agent.id == this.expanded[0].id ? 'Corban' : 'Indicador';

            return a;
          });
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.expandedIsLoading = false;
        });
    },

    openModalCreatePaymentOrder(commissionAccount) {
      this.selectedAccount = commissionAccount;
      this.paymentOrder = new CommissionPaymentOrderModel();
      this.paymentOrderDocumentNf = undefined;
      this.paymentOrderDocumentProof = undefined;

      this.modalCreatePaymentOrder = true;
    },

    createPaymentOrder() {
      this.paymentOrder.agentId = this.selectedAccount.agent.id;
      this.paymentOrder.paymentDate = new Date();

      apiPaymentOrder
        .add(this.paymentOrder)
        .then((response) => {
          this.createPaymentOrderNfDocument(response.data);
          this.createPaymentOrderProofDocument(response.data);
          this.findRelatedAccount();
          this.modalCreatePaymentOrder = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    createPaymentOrderNfDocument(paymentOrder) {
      let formData = new FormData();

      formData.append('paymentOrderId', paymentOrder.id);
      formData.append('documentTypeId', COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.NF.id);
      formData.append('document', this.paymentOrderDocumentNf);

      apiPaymentOrderDocument
        .add(formData)
        .then(() => {})
        .catch(() => {});
    },

    createPaymentOrderProofDocument(paymentOrder) {
      let formData = new FormData();

      formData.append('paymentOrderId', paymentOrder.id);
      formData.append('documentTypeId', COMMISSION_PAYMENT_ORDER_DOCUMENT_TYPE.PROOF.id);
      formData.append('document', this.paymentOrderDocumentProof);

      apiPaymentOrderDocument
        .add(formData)
        .then(() => {})
        .catch(() => {});
    },

    addDocument(document) {
      let formData = new FormData();

      formData.append('paymentOrderId', document.paymentOrderId);
      formData.append('documentTypeId', document.documentTypeId.id);
      formData.append('document', document.document);

      return apiPaymentOrderDocument
        .add(formData)
        .then((response) => {
          return (document = {
            ...response.data
          });
        })
        .catch(() => {
          this.sendMessage('Erro ao adicionar o anexo!', 'error');
          document.document = undefined;

          return document;
        });
    },

    downloadDocument(document) {
      return apiPaymentOrderDocument.download(document.id);
    },

    callbackSendCommissionReports() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CORBAN_RELATORIO_FECHAMENTO
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage('Relatórios de comissões enviados com sucesso!', 'success');
          this.openConfirmationWindow = false;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    }
  }
};
</script>

<style scoped lang="scss">
.remaining-value {
  color: var(--v-primary-base);
}
</style>
