<template>
  <div v-if="!loading">
    <AddressComponent
      data-test="address-comercial"
      address-name="Endereço comercial"
      v-model="customer.professionalAddress"
      required
    />
    <v-checkbox
      data-test="ck-endereco-correspondencia"
      label="Usar o mesmo endereço para correspondência"
      v-model="deliveryCheck"
      dense
    />
    <AddressComponent
      v-if="!deliveryCheck"
      data-test="address-correspondencia"
      address-name="Endereço de correspondência"
      v-model="customer.deliveryAddress"
    />
  </div>
</template>

<script>
// Constants
import { ADDRESS_TYPE } from '../../../../../../common/constants/generic/types';

// Component
import AddressComponent from '@/components/comum/AddressComponent.vue';

// Models
import CustomerAddressModel from '@/model/customer-address-model';

export default {
  name: 'PJCustomerAddress',
  components: { AddressComponent },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object
    }
  },
  data() {
    return {
      deliveryCheck: true,
      customer: this.customerProp,
      loading: true
    };
  },
  watch: {
    'customer.deliveryAddress': {
      handler() {
        this.fillCustomerAddress();
      },
      deep: true
    },
    'customer.professionalAddress': {
      handler() {
        this.fillCustomerAddress();
      },
      deep: true
    }
  },
  mounted() {
    this.mountAddress();
  },
  methods: {
    fillCustomerAddress() {
      let addresses = [];

      // Done this way because Object.assign was generating an infinite loop
      let professionalJSON = JSON.stringify(this.customer.professionalAddress);
      let professional = JSON.parse(professionalJSON);
      professional.addressType = [];
      professional.addressType.push(ADDRESS_TYPE.PROFESSIONAL);

      addresses.push(professional);

      if (this.deliveryCheck) {
        professional.addressType.push(ADDRESS_TYPE.DELIVERY);
      } else {
        let deliveryJSON = JSON.stringify(this.customer.deliveryAddress);
        let delivery = JSON.parse(deliveryJSON);
        delivery.addressType = [];
        delivery.addressType.push(ADDRESS_TYPE.DELIVERY);
        addresses.push(delivery);
      }

      this.customer.addresses = addresses;
      this.$emit('onChange', this.customer);
    },

    mountAddress() {
      if (this.customer.addresses.length > 0) {
        let addresses = this.customer.addresses;
        for (let i = 0; i < addresses.length; i++) {
          const address = addresses[i];

          for (let j = 0; j < address.addressType.length; j++) {
            const type = address.addressType[j];

            if (type.id == ADDRESS_TYPE.DELIVERY.id) {
              this.customer.deliveryAddress = new CustomerAddressModel(address);
            }

            if (type.id == ADDRESS_TYPE.PROFESSIONAL.id) {
              this.customer.professionalAddress = new CustomerAddressModel(address);
            }
          }
        }

        if (this.customer.professionalAddress.id == this.customer.deliveryAddress.id) {
          this.customer.deliveryAddress = new CustomerAddressModel();
          this.deliveryCheck = true;
        } else {
          this.deliveryCheck = false;
        }

        this.$emit('onChange', this.customer);
      }

      this.loading = false;
    }
  }
};
</script>

<style></style>
