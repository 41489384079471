import dateUtils from '../../../common/utils/date';
import numberUtils from '../../../common/utils/number';
import { OPERATION_TYPE } from "@/constants/general-constants";


function getCustomerMessage(operation) {
  if (operation.exchangeType.id == OPERATION_TYPE.REMMITANCE.id) {
    return _getCustomerMessageRemittance(operation);
  } else {
    return _getCustomerMessageExchange(operation);
  }
}

function _getCustomerMessageRemittance(operation) {
  if (operation.exchangeBuy) {
    return _getCustomerMessageRemittanceBuy(operation);
  } else {
    return _getCustomerMessageRemittanceSell(operation);
  }
}

function _getCustomerMessageRemittanceBuy(operation) {
  return `
*Simulação de operação - ME - ${dateUtils.maskDateAndHourIso(new Date())}*

Tipo Operação -  Compra / Entrada
Moeda         -  ${operation.currency.name}
Nat. Operação -  ${operation.nature.exibitionName}

VALOR ME      -  ${numberUtils.formatCurrency(operation.currency.code, operation.amount)}
*Tx. de Câmbio -  ${numberUtils.formatCurrency('BRL', operation.customerRate, operation.currency.precision)}*

IOF (${operation.iof}%)   -  ${numberUtils.formatCurrency('BRL', operation.iofValue)}
I.R (${operation.ir}%)   -  ${numberUtils.formatCurrency('BRL', operation.irValue)}
Tarifa        -  ${numberUtils.formatCurrency('BRL', operation.exchangeContractCost)}

V.E.T.        -  ${numberUtils.formatCurrency('BRL', operation.vet, operation.currency.precision)}

TOTAL                     ${numberUtils.formatCurrency('BRL', operation.totalValue)}

*Cotação válida nos próximos 5 minutos*
*Sujeito a variação de acordo com o mercado*

✅*AGUARDO SUAS INSTRUÇÕES PARA O FECHAMENTO JUNTO AO BANCO* 
`;
}

function _getCustomerMessageRemittanceSell(operation) {
  return `
*Simulação de operação - ME - ${dateUtils.maskDateAndHourIso(new Date())} *

Tipo Operação -  Venda / Saída
Moeda         -  ${operation.currency.name}
Nat. Operação -  ${operation.nature.exibitionName}

VALOR ME      -  ${numberUtils.formatCurrency(operation.currency.code, operation.amount)}
*Tx. de Câmbio -  ${numberUtils.formatCurrency('BRL', operation.customerRate, operation.currency.precision)}*

IOF (${operation.iof}%)   -  ${numberUtils.formatCurrency('BRL', operation.iofValue)}
I.R (${operation.ir}%)   -  ${numberUtils.formatCurrency('BRL', operation.irValue)}
Tarifa        -  ${numberUtils.formatCurrency('BRL', operation.exchangeContractCost)}

V.E.T.        -  ${numberUtils.formatCurrency('BRL', operation.vet, operation.currency.precision)}

TOTAL                     ${numberUtils.formatCurrency('BRL', operation.totalValue)}

*Cotação válida nos próximos 5 minutos*
*Sujeito a variação de acordo com o mercado*

✅ *AGUARDO SUAS INSTRUÇÕES PARA O FECHAMENTO JUNTO AO BANCO* 
`;
}

function _getCustomerMessageExchange(operation) {
  if (operation.exchangeBuy) {
    return _getCustomerMessageExchangeBuy(operation);
  } else {
    return _getCustomerMessageExchangeSell(operation);
  }
}

function _getCustomerMessageExchangeBuy(operation) {
  return `
Simulação de Operação - ${dateUtils.maskDateAndHourIso(new Date())}

🏦Loja para Entrega - *${operation.store.name + ' - ' + operation.storeCity}*

Tipo - *Compra*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, operation.currency.precision)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, operation.currency.precision)}

Total a Receber - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

*Cotação válida nos próximos 5 minutos.**
*Sujeito a variação de acordo com o mercado***
*Aguarde confirmação de disponibilidade da moeda.****
`;
}

function _getCustomerMessageExchangeSell(operation) {
  if (operation.deliveryType.id == OPERATION_TYPE.REMMITANCE.id) {
    return _getCustomerMessageExchangeSellWithdrawal(operation);
  } else {
    return _getCustomerMessageExchangeSellDelivery(operation);
  }
}

function _getCustomerMessageExchangeSellWithdrawal(operation) {
  return `
Simulação de Operação - ${dateUtils.maskDateAndHourIso(new Date())}

🏦Loja de Retirada - *${operation.store.name + ' - ' + operation.storeCity}*

Tipo - *Venda*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, operation.currency.precision)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, operation.currency.precision)}

Total a Pagar - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

*Cotação válida nos próximos 5 minutos.**
*Sujeito a variação de acordo com o mercado***
*Aguarde confirmação de disponibilidade da moeda.****
`;
}

function _getCustomerMessageExchangeSellDelivery(operation) {
  return `
Simulação de Operação - ${dateUtils.maskDateAndHourIso(new Date())}

🏦Local da Entrega - ${operation.street + ', ' + operation.number + ' ' + operation.city}

Tipo - *Venda*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, operation.currency.precision)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
Custo da Entrega - ${numberUtils.formatCurrency('BRL', operation.deliveryCost)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, operation.currency.precision)}

Total a Pagar - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

*Cotação válida nos próximos 5 minutos.**
*Sujeito a variação de acordo com o mercado***
*Aguarde confirmação de disponibilidade da moeda.****
`;
}


export default {
  getCustomerMessage,
};