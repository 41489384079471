export default class SocketData {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.title = obj.title;
    this.message = obj.message;
    this.data = obj.data;
    this.date = obj.date;
  }
}
