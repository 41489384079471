<template>
  <v-row no-gutters>
    <v-col>
      <div>
        <ConfirmationWindow
          v-model="openConfirmationWindow"
          :callback-primary="confirmationWindowCallback"
          :message="confimationWindowMessage"
        />
      </div>

      <v-row no-gutters>
        <v-col>
          <h1>Limites</h1>
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="py-2"
      >
        <v-col>
          <v-btn
            class="btn-primary ml-0"
            text
            @click="openAddExchangeBankModal"
            data-test="btn-adicionar-banco"
          >
            <i class="far fa-plus" />
            Adicionar Banco
          </v-btn>
        </v-col>

        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            data-test="ipt-pesquisar"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-data-table
            :headers="columns"
            :items="customer.exchangeBanks"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="paginationDefault"
            data-test="tbl-bancos"
          >
            <template #[`item.operationNatures`]="{ item }">
              <v-chip
                v-for="(nature, i) in item.operationNatures"
                :key="i"
                small
                label
                class="ma-1"
              >
                {{ nature.exibitionName }}
              </v-chip>
            </template>

            <template #[`item.limitIn`]="{ item }">
              <template v-if="item.limit.isInOut">
                {{ item | formatLimit(item.limit.remainingIn) }}
              </template>
              <template v-else>
                <v-icon class="hidden-sm-and-down">mdi-arrow-right-bottom</v-icon>
                <i class="fas fa-long-arrow-down fa-lg hidden-md-and-up" />
              </template>
            </template>

            <template #[`item.limit.remaining`]="{ item }">
              <template v-if="!item.limit.isInOut">
                {{ item | formatLimit(item.limit.remaining) }}
              </template>
            </template>

            <template #[`item.limitOut`]="{ item }">
              <template v-if="item.limit.isInOut">
                {{ item | formatLimit(item.limit.remainingOut) }}
              </template>
              <template v-else>
                <v-icon class="hidden-sm-and-down">mdi-arrow-left-bottom</v-icon>
                <i class="fas fa-long-arrow-up fa-lg hidden-md-and-up" />
              </template>
            </template>

            <template #[`item.limit.expireAt`]="{ item }">
              {{ item.limit.expireAt | date }}
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu
                offset-y
                top
                :close-on-content-click="false"
              >
                <template #activator="{ on: menu }">
                  <v-tooltip top>
                    <template #activator="{ on: tooltip, attrs }">
                      <v-btn
                        icon
                        text
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        @click.stop
                      >
                        <i class="far fa-ellipsis-v" />
                      </v-btn>
                    </template>
                    <span>Opções</span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-list-item @click="editExchangeBankModal(item)">
                    <v-list-item-title>
                      <i class="far fa-pencil pr-2 menu-buttons" /> Editar
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="openAddLimitModal(item)">
                    <v-list-item-title>
                      <i class="far fa-hand-holding-usd pr-2 menu-buttons" /> Alterar Limite
                    </v-list-item-title>
                  </v-list-item>

                  <CustomerBankStatement
                    :customer="customer"
                    :exchange-bank="item"
                  />

                  <v-list-item @click="sendRegisterUpdatedToBank(item)">
                    <v-list-item-title>
                      <i class="far fa-envelope pr-2 menu-buttons" /> Enviar Atualização ao Banco
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="createRegisterForm(item)">
                    <v-list-item-title>
                      <i class="far fa-id-card pr-2 menu-buttons" /> Gerar Ficha Cadastral
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Add Exchange Bank Modal -->
      <v-dialog
        persistent
        scrollable
        eager
        min-width="40vw"
        max-width="100vh"
        v-model="addExchangeBankModal"
      >
        <v-card>
          <v-card-title>
            <h1
              class="titulo-modal"
              data-test="titulo-modal-banco"
            >
              {{ `${update ? 'Editar' : 'Adicionar'} banco` }}
            </h1>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-5">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-form
                  id="add-exchange-bank"
                  ref="form"
                >
                  <v-row>
                    <v-col md="12">
                      <v-autocomplete
                        label="Tipo de operação"
                        :items="filteredExchangeTypes"
                        item-text="description"
                        item-value="id"
                        v-model="exchangeBank.exchangeType"
                        return-object
                        clearable
                        dense
                        outlined
                        v-disabled-icon-focus
                        data-test="complete-tipo-operacao"
                      />
                    </v-col>
                    <v-col
                      md="12"
                      v-if="exchangeBank.exchangeType.id == 1"
                    >
                      <v-autocomplete
                        label="Banco"
                        :items="getRemittanceBanks"
                        item-text="fantasyName"
                        item-value="id"
                        v-model="exchangeBank.bank"
                        clearable
                        dense
                        outlined
                        v-disabled-icon-focus
                        return-object
                        data-test="complete-tipo-remessa-banco"
                      />
                    </v-col>
                    <v-col
                      md="12"
                      v-else
                    >
                      <v-autocomplete
                        label="Banco"
                        :items="getTourismBanks"
                        item-text="fantasyName"
                        item-value="id"
                        v-model="exchangeBank.bank"
                        clearable
                        dense
                        outlined
                        v-disabled-icon-focus
                        return-object
                        data-test="complete-banco"
                      />
                    </v-col>
                    <v-col md="12">
                      <v-autocomplete
                        label="Natureza da Operação"
                        :items="operationNatureList"
                        item-text="exibitionName"
                        item-value="id"
                        v-model="exchangeBank.operationNatures"
                        clearable
                        dense
                        outlined
                        multiple
                        v-disabled-icon-focus
                        return-object
                        data-test="complete-natureza-operacao"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-col class="text-right">
              <v-btn
                v-if="!update"
                text
                class="btn-primary mr-2"
                @click="addExchangeBank"
                data-test="btn-salvar-banco"
              >
                Salvar
              </v-btn>
              <v-btn
                v-else-if="update"
                text
                class="btn-primary mr-2"
                @click="updateExchangeBank"
                data-test="btn-atualizar-banco"
              >
                Salvar
              </v-btn>
              <v-btn
                text
                class="btn-tertiary"
                @click="addExchangeBankModal = false"
                data-test="btn-cancelar-banco"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add Limit Modal -->
      <v-dialog
        persistent
        scrollable
        eager
        min-width="40vw"
        max-width="100vh"
        v-model="addLimitModal"
      >
        <v-card>
          <v-card-title>
            <h1 class="titulo-modal">Limite aprovado</h1>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-5">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-form
                  id="add-limit-modal"
                  ref="form"
                >
                  <v-row>
                    <template v-if="exchangeBank.useInOutLimit">
                      <v-col md="12">
                        <MoneyField
                          data-test="txt-valor-entrada"
                          label="Valor Entrada"
                          :prefix="limitPrefix"
                          v-model="limit.valueIn"
                        />
                      </v-col>
                      <v-col md="12">
                        <MoneyField
                          data-test="txt-valor-saida"
                          label="Valor Saída"
                          :prefix="limitPrefix"
                          v-model="limit.valueOut"
                        />
                      </v-col>
                    </template>
                    <v-col
                      v-else
                      md="12"
                    >
                      <MoneyField
                        data-test="txt-valor"
                        label="Valor"
                        :prefix="limitPrefix"
                        v-model="limit.value"
                      />
                    </v-col>
                    <v-col md="12">
                      <Datepicker
                        data-test="dt-data-validade"
                        v-model="limit.expireAt"
                        label="Data de Validade"
                        v-disabled-icon-focus
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-col class="text-right">
              <v-btn
                text
                class="btn-primary mr-2"
                @click="addLimit"
                data-test="btn-salvar-limite"
              >
                Salvar
              </v-btn>
              <v-btn
                text
                class="btn-tertiary"
                @click="addLimitModal = false"
                data-test="btn-cancelar-limite"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Send To Bank Modal -->
      <v-dialog
        persistent
        scrollable
        eager
        min-width="40vw"
        max-width="100vh"
        v-model="sendToBankModal"
      >
        <v-card>
          <v-card-title>
            <h1 class="titulo-modal">Documentos</h1>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-5">
            <v-row v-if="customer.documents.length > 0">
              <v-col
                class="py-0"
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="selectAllDocuments"
                  label="Selecionar todos"
                  @change="toggleAllDocuments"
                  data-test="chk-todos-documentos"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="12"
                v-for="(document) in customer.documents"
                :key="document.id"
              >
                <v-checkbox
                  v-model="selectedDocuments"
                  :label="getDescriptionDocument(document)"
                  :value="document.id"
                  :data-test="`chk-documento-${document.id}`"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-card
                width="100%"
                height="100px"
                class="card-cambio d-flex flex-column justify-center align-center"
              >
                <span>Não há documentos</span>
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-title>
            <h1 class="titulo-modal">Documentos complementares</h1>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-5">
            <v-row v-if="customer.operationalDocuments.length > 0">
              <v-col
                class="py-0"
                cols="12"
                md="12"
              >
                <v-checkbox
                  v-model="selectAllOperationalDocuments"
                  label="Selecionar todos"
                  @change="toggleAllOperationalDocuments"
                  data-test="chk-todos-documentos-complementares"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="12"
                v-for="(document) in customer.operationalDocuments"
                :key="document.id"
              >
                <v-checkbox
                  v-model="selectedOperationalDocuments"
                  :label="getDescriptionDocument(document, true)"
                  :value="document.id"
                  :data-test="`chk-documento-complementar-${document.id}`"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-card
                width="100%"
                height="100px"
                class="card-cambio d-flex flex-column justify-center align-center"
              >
                <span>Não há documentos complementares</span>
              </v-card>
            </v-row>
            <v-row>
              <v-textarea
                class="pt-5"
                auto-grow
                outlined
                rows="1"
                v-model="bankComplementaryInfo"
                label="Informações complementares"
                data-test="txt-informacoes-complementares"
              />
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <span>*Somente serão exibidos documentos informados na aba "Documentos"</span>
            <v-col class="text-right">
              <v-btn
                text
                class="btn-primary mr-2"
                @click="confirmationWindowCallback"
                data-test="btn-enviar-banco"
              >
                Enviar ao Banco
              </v-btn>
              <v-btn
                text
                class="btn-tertiary"
                @click="cancelSendToBank()"
                data-test="btn-cancelar-envio-banco"
              >
                Cancelar envio
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// Api
import ExchangeBankApi from "@/api/exchangeBank/exchange-bank-api";
import OperationNatureApi from "@/api/configuration/operation-nature-api";
import ExchangeBankTypeApi from "@/api/exchangeBank/exchange-bank-type-api";
import CustomerApi from "@/api/customer/customer-api";
import TriggerApi from "@/api/generic/trigger-api";

// Components
import MoneyField from "@/components/comum/MoneyField";
import Datepicker from "@/components/comum/Datepicker.vue";
import CustomerBankStatement from "@/components/form-customer/CustomerBankStatement.vue";
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";

// Models
import CustomerExchangeBankModel from "@/model/customer-exchange-bank-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import OperationNatureModel from "@/model/operation-nature-model";
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";
import CustomerLimitModel from "@/model/customer-limit-model";
import TriggerModel from "@/model/generic/trigger-model";

// Utils
import dateUtils from "../../../../common/utils/date";
import numberUtils from "../../../../common/utils/number";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Constants
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { CUSTOMER_WORKFLOW } from "../../../../common/workflows/customersWorkflow";
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: "CustomerExchangeBanks",
  mixins: [mixinMessage],
  components: {
    MoneyField,
    Datepicker,
    CustomerBankStatement,
    ConfirmationWindow,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
      default: () => {}
    },
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatLimit(customerExchangeBank, value) {
      if (customerExchangeBank.bank.useUsdLimit) {
        return numberUtils.formatCurrency('USD', value);
      }

      return numberUtils.formatCurrency('BRL', value);
    },
  },
  inject: ['appData'],
  data() {
    return {
      customer: this.customerProp,
      search: "",
      banks: [],
      operationNatureList: [],
      exchangeTypes: [],
      columns: [
        { text: "Tipo", value: "exchangeType.description", align: "left", width: "130px" },
        { text: "Banco vinculado", value: "bank.fantasyName", align: "left", width: "150px" },
        { text: "Naturezas aprovadas", value: "operationNatures", align: "left", width: "10%" },
        { text: "Limite disponível entrada", value: "limitIn", align: "center", width: "10%" },
        { text: "", value: "limit.remaining", align: "left", width: "5%" },
        { text: "Limite disponível saída", value: "limitOut", align: "center", width: "10%" },
        { text: "Validade do limite", value: "limit.expireAt", align: "left", width: "10%" },
        { text: "", value: "actions", align: "right" },
      ],
      exchangeBank: new CustomerExchangeBankModel(),
      addExchangeBankModal: false,
      update: false,
      addLimitModal: false,
      limit: new CustomerLimitModel(),
      openConfirmationWindow: false,
      confirmationWindowCallback: () => {},
      confimationWindowMessage: '',
      sendToBankModal: false,
      selectedDocuments: [],
      selectedOperationalDocuments: [],
      currentBank: undefined,
      bankComplementaryInfo: '',
      apiExchangeBankType: new ExchangeBankTypeApi(this.appData.currentUser),
      customerApi: new CustomerApi(this.appData.currentUser),
      operationNatureApi: new OperationNatureApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
      selectAllDocuments: false,
      selectAllOperationalDocuments: false,
      paginationDefault: PAGINATION_DEFAULT,
      exchangeBankApi: new ExchangeBankApi(this.appData.currentUser),
    };
  },
  created () {
    this.$eventBus.$on('customer-pre-registered', this.addExchangeBankCustomerRegistered);
  },
  beforeDestroy () {
    this.$eventBus.$off('customer-pre-registered', this.addExchangeBankCustomerRegistered);
  },
  mounted() {
    this.getBankList();
    this.getOperationNatureList();
    this.getExchangeTypes();
  },
  computed: {
    limitPrefix() {
      return this.exchangeBank.useUsdLimit ? 'USD ' : 'R$ ';
    },

    getRemittanceBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 2);
    },

    getTourismBanks() {
      if (this.banks.length == 0) {
        return [];
      }

      return this.banks.filter(b => b.exchangeType.id != 1);
    },

    filteredExchangeTypes() {
      let filtered = this.exchangeTypes.filter(i => i.id != 3);

      return filtered;
    }
  },
  methods: {
    openAddExchangeBankModal() {
      this.update = false;
      this.exchangeBank = new CustomerExchangeBankModel();
      this.addExchangeBankModal = true;
    },

    editExchangeBankModal(exchangeBank) {
      this.update = true;
      this.exchangeBank = Object.assign({}, exchangeBank);
      this.addExchangeBankModal = true;
    },

    addExchangeBank() {
      this.customer.id
        ? this.addExchangeBankCustomerRegistered()
        : this.addExchangeBankNewCustomer();
    },

    addExchangeBankNewCustomer() {
      this.$eventBus.$emit('create-customer-pre-registration');
    },

    addExchangeBankCustomerRegistered() {
      this.customer.exchangeBanks.push(new CustomerExchangeBankModel(this.exchangeBank));
      this.addExchangeBankModal = false;
      this.confirmationWindowCallback = this.callbackSendBankRegister;
      this.sendToBankModal = true;
      this.updateStatusToSentToBank();
      this.$eventBus.$emit('customer-auto-save');
    },

    updateExchangeBank() {
      let index = this.customer.exchangeBanks.findIndex((b) =>
        (b.bank.id == this.exchangeBank.bank.id && b.exchangeType.id == this.exchangeBank.exchangeType.id)
      );

      this.customer.exchangeBanks.splice(
        index,
        1,
        new CustomerExchangeBankModel(this.exchangeBank)
      );
      this.addExchangeBankModal = false;

      this.$eventBus.$emit('customer-auto-save');
    },

    getBankList() {
      this.exchangeBankApi
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    getOperationNatureList() {
      this.operationNatureApi
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getExchangeTypes() {
      this.apiExchangeBankType
        .findAll()
        .then((response) => {
          this.exchangeTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddLimitModal(exchangeBank) {
      this.exchangeBank = Object.assign({}, exchangeBank.bank);

      this.limit = new CustomerLimitModel();
      let expire = new Date();
      expire.setFullYear(expire.getFullYear() + 1);
      this.limit.expireAt = expire.toISOString();

      this.addLimitModal = true;
    },

    addLimit() {
      if (this.exchangeBank.useInOutLimit) {
        this.limit.remainingIn = this.limit.valueIn;
        this.limit.remainingOut = this.limit.valueOut;
        this.limit.isInOut = true;
      } else {
        this.limit.remaining = this.limit.value;
      }

      this.customerApi
        .addLimit(this.customer.id, this.exchangeBank.id, this.limit)
        .then((response) => {
          let index = this.customer.exchangeBanks.findIndex((b) => (b.bank.id == this.exchangeBank.id));

          this.customer.exchangeBanks[index].limit = new CustomerLimitModel(response.data);
          this.addLimitModal = false;
          this.confirmationWindowCallback = this.callbackSendLimitAproval;
          this.confimationWindowMessage = 'Deseja enviar a aprovação de limite ao cliente?';
          this.openConfirmationWindow = true;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    callbackSendLimitAproval() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_APROVACAO_LIMITE,
        idCustomer: this.customer.id,
        idBank: this.exchangeBank.id,
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Aprovação de limite enviada com sucesso!", "success");
          this.openConfirmationWindow = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    callbackSendBankRegister() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.BANCO_ENVIO_CADASTRO,
        idCustomer: this.customer.id,
        idBank: this.exchangeBank.bank.id,
        documentIds: this.selectedDocuments,
        customerOperationalDocumentIds: this.selectedOperationalDocuments,
        bankComplementaryInfo: this.bankComplementaryInfo,
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Ficha de cadastro enviada ao banco com sucesso!", "success");
          this.sendToBankModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    sendRegisterUpdatedToBank(exchangeBank){
      this.currentBank = exchangeBank;
      this.confirmationWindowCallback = this.callbackSendRegisterUpdatedToBankTrigger;
      this.sendToBankModal = true;
      this.selectAllDocuments = false;
      this.selectAllOperationalDocuments = false;
      this.selectedDocuments = [];
      this.selectedOperationalDocuments = [];
      this.bankComplementaryInfo = '';
    },

    cancelSendToBank() {
      this.sendToBankModal = false;
      this.selectAllDocuments = false;
      this.selectAllOperationalDocuments = false;
      this.selectedDocuments = [];
      this.selectedOperationalDocuments = [];
      this.bankComplementaryInfo = '';
    },

    callbackSendRegisterUpdatedToBankTrigger() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.BANCO_ATUALIZACAO_CADASTRAL,
        idCustomer: this.customer.id,
        idBank: this.currentBank.bank.id,
        documentIds: this.selectedDocuments,
        customerOperationalDocumentIds: this.selectedOperationalDocuments,
        bankComplementaryInfo: this.bankComplementaryInfo,
      });

      this.triggerApi
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Email de atualização cadastral enviado ao banco com sucesso!", "success");
          this.$router.push({ name: 'CustomersAuthorizationControl' }); 
          this.sendToBankModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateStatusToSentToBank() {
      this.customerApi
        .updateStatus(this.customer.id, CUSTOMER_WORKFLOW.ENVIADO_AO_BANCO)
        .then(() => { })
        .catch(() => { });
    },

    createRegisterForm(exchangeBank) {
      this.customerApi
        .downloadRegisterForm(this.customer.id, exchangeBank.bank.id)
        .then((response) => {
          let link = document.createElement("a");

          link.href = URL.createObjectURL(response.data);
          link.setAttribute(
            "download",
            `FichaCadastral.pdf`
          );
          link.click();

          URL.revokeObjectURL(link.href);

          this.sendMessage("Baixando anexo", "success");
        })
        .catch(() => {
          this.sendMessage("Falha ao baixar o anexo. Verifique se há um template vinculado a esse banco.", "error");
        });
    },

    toggleAllDocuments() {
      if (this.selectAllDocuments) {
        this.selectedDocuments = this.customer.documents.map(document => document.id);
      } else {
        this.selectedDocuments = [];
      }
    },

    toggleAllOperationalDocuments() {
      if (this.selectAllOperationalDocuments) {
        this.selectedOperationalDocuments = this.customer.operationalDocuments.map(document => document.id);
      } else {
        this.selectedOperationalDocuments = [];
      }
    },

    getDescriptionDocument(document) {
      if (document?.operationalDocumentType?.description) {
        let index = this.customer.operationalDocuments.findIndex(d => d.id === document.id);

        return document.description
          ? `${document.operationalDocumentType.description} - ${document.description}`
          : `${document.operationalDocumentType.description} - Compl. ${index+1}`;
      }

      const OTHERS_DOCUMENTS = 7;

      let index = this.customer.documents.findIndex(d => d.id === document.id);

      return document.documentType.id === OTHERS_DOCUMENTS
        ? document.description
          ? `${document.documentType.description} - ${document.description}`
          : `${document.documentType.description} ${index+1}`
        : document.documentType.description;
    }
  }
};
</script>

<style>
.menu-buttons {
  color: var(--v-primary-base);
}
</style>