<template>
  <div class="mx-2 mt-4 ma-md-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader
          type="button"
          min-width="100px"
          data-test="skeleton-loader-button"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
          data-test="skeleton-loader-thead"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
          data-test="skeleton-loader-tbody"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
          data-test="skeleton-loader-tfoot"
        />
      </v-col>
    </v-row>

    <v-row
      v-else
      no-gutters
    >
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="titulo">Perfis</h1>
            <v-divider />
          </v-col>
        </v-row>

        <v-row 
          no-gutters
          class="py-2"
        >
          <v-col
            cols="12"
            md="9"
            sm="8"
            align-self="center"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="addProfiles"
              data-test="btn-adicionar-perfil"
            >
              <i class="far fa-plus" />
              Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="4"
            class="pa-0 pt-2 pt-md-0 pr-3"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              class="pt-0 mt-0"
              data-test="txt-pesquisar-perfil"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="profiles"
                :search="search"
                sort-by="id"
                sort-desc
                :items-per-page="paginationDefault"
                :footer-props="itemsPerPageOptions"
                height="calc(100dvh - 310px)"
                fixed-header
                item-key="id"
                data-test="tabela-perfis"
              >
                <template #[`item.name`]="{ item }">
                  <span>{{ item.name }}</span>
                </template>
                <template #[`item.actions`]="{ item }">
                  <template>
                    <v-menu
                      offset-y
                      left
                    >
                      <template #activator="{ on: menu }">
                        <v-tooltip top>
                          <template #activator="{ on: tooltip, attrs }">
                            <v-btn
                              icon
                              text
                              v-bind="attrs"
                              v-on="{ ...tooltip, ...menu }"
                              @click.stop
                              data-test="btn-acoes-perfil"
                            >
                              <i class="far fa-ellipsis-v" />
                            </v-btn>
                          </template>
                          <span>Opções</span>
                        </v-tooltip>
                      </template>

                      <v-list>
                        <v-list-item 
                          @click="editProfile(item)" 
                          data-test="btn-editar-perfil"
                        >
                          <i class="far fa-pencil pr-2 menu-buttons" />
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>

                        <v-list-item 
                          @click="deleteProfileConfirmationDialog(item)" 
                          data-test="btn-excluir-perfil"
                        >
                          <i class="far fa-trash pr-2 menu-buttons" />
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <confirmation-window
      v-model="openWindowConfirmation"
      :callback-primary="callbackConfirmDeletion"
      :message="deleteMessage"
      data-test="janela-confirmacao"
    />
  </div>
</template>

<script>
// Apis
import accessProfileApi from '../../api/access/access-profile-api';

// Models
import AccessProfileModel from '@/model/access/access-profile-model';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinAuthorization from '../../mixin/mixin-authorization';

// Components
import ConfirmationWindow from '@/components/comum/ConfirmationWindow';

// Tracking
import mixpanel from 'mixpanel-browser';
import { PAGINATION_DEFAULT, ITEMS_PER_PAGE_DEFAULT } from '../../constants/general-constants';

export default {
  name: 'ProfilesControl',
  mixins: [mixinMessage, mixinAuthorization],
  components: {
    ConfirmationWindow
  },
  data() {
    return {
      loading: true,
      openWindowConfirmation: false,
      deleteMessage: '',
      search: '',
      profiles: [],
      profile: new AccessProfileModel(),
      callbackConfirmDeletion: () => {},
      columns: [
        { text: 'Código', value: 'id', align: 'left', sortable: true },
        { text: 'Nome', value: 'description', align: 'left', sortable: true },
        { text: 'Observação', value: 'observation', align: 'left', sortable: true },
        { text: '', value: 'actions', align: 'right', sortable: false }
      ],
      paginationDefault: PAGINATION_DEFAULT,
      itemsPerPageOptions: ITEMS_PER_PAGE_DEFAULT
    };
  },
  mounted() {
    this.getProfilesList();

    mixpanel.track('page_view', {
      name_of_page_viewed: 'profiles_control',
      user_id: this.user.id
    });
  },

  computed: {},

  methods: {
    getProfilesList() {
      return accessProfileApi
        .findAll()
        .then(({ data }) => {
          this.profiles = data.map((p) => new AccessProfileModel(p));
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage(error.response?.data?.mensagem || error, 'error');
          }

          this.profiles = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    inactiveProfiles() {
      return accessProfileApi
        .inactive(this.profile)
        .then(() => {
          this.sendMessage(`Perfil ${this.profile.id} excluído com sucesso`, 'success');

          const index = this.profiles.findIndex((p) => p.id === this.profile.id);

          this.profiles.splice(index, 1);

          this.openWindowConfirmation = false;
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage(error.response?.data?.mensagem || error, 'error');
          }
        });
    },

    addProfiles() {
      mixpanel.track('click', {
        button_name: 'add_profile'
      });

      this.$router.push({
        name: 'FormCreateProfile'
      });
    },

    editProfile(profile) {
      mixpanel.track('click', {
        button_name: 'edit_profile'
      });

      this.$router
        .push({
          name: 'FormEditProfile',
          params: {
            id: profile.id
          }
        })
        .catch(() => {});
    },

    deleteProfileConfirmationDialog(profile) {
      mixpanel.track('click', {
        button_name: 'exclude_profile'
      });

      this.profile = profile;
      this.deleteMessage = `Deseja realmente excluir o perfil&nbsp;<strong>"${profile.description}"</strong>?`;
      this.callbackConfirmDeletion = this.inactiveProfiles;
      this.openWindowConfirmation = true;
    }
  }
};
</script>

<style scoped lang="scss"></style>
