<template>
  <v-app>
    <Menu
      v-model="isOpenSimulator"
      v-if="shoudShowMenu()"
      :key="key"
    />
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="type"
      elevation="0"
      right
    >
      {{ message }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <OperationSimulator
      v-model="isOpenSimulator"
      v-if="shoudShowSimulator()"
    />
    <v-main>
      <router-view />

      <!-- Loading -->
      <v-dialog
        v-model="loading"
        persistent
        width="200"
        height="200"
        class="pa-5"
      >
        <v-card class="pa-5">
          <v-card-text class="d-flex justify-center align-center text-center flex-column">
            <v-progress-circular
              :size="80"
              indeterminate
              color="primary"
              class="m-0"
            />
            <h2 class="pt-2">Carregando</h2>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="footer">
        <div class="by">
          &copy; {{ new Date().getFullYear() }} - Powered by DeepRocket Tecnologia LTDA
        </div>
        <div by="version">{{ version }}</div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
//Constants
import { OPTIONS_STORE_ACCESS } from './vuex/module/access';

// Components
import Menu from '@/components/menu/Menu';
import OperationSimulator from '@/components/comum/OperationSimulator';

// Mixins
import mixinAuthorization from '@/mixin/mixin-authorization';
import mixinMessage from '@/mixin/mixin-message';

// Storage
import utilsStorage from '@/utils/storage';

import { mapGetters } from 'vuex/dist/vuex.common.js';
import socketIOClient from 'socket.io-client';
import BaseApi from '../src/api/baseApi';

import SocketData from '../../common/models/SocketData';

export default {
  name: 'App',
  mixins: [mixinAuthorization, mixinMessage],
  components: {
    Menu,
    OperationSimulator
  },

  provide() {
    const appData = {};

    /* Necessário para que a propriedade seja reativa */
    Object.defineProperty(appData, 'currentUser', {
      get: () => this.user
    });

    return {
      appData
    };
  },

  data() {
    return {
      isOpenSimulator: false,
      version: `${process.env.VUE_APP_NAME} - ${process.env.VUE_APP_APP_VERSION}`,
      message: '',
      snackbar: false,
      timeout: 5000,
      type: '',
      key: 0,
      loading: false,
      socket: null
    };
  },
  watch: {
    getAccess: {
      handler() {
        this.key++;
      },
      deep: true
    }
  },

  async mounted() {
    if (this.user?.id) {
      await this.$store.dispatch(OPTIONS_STORE_ACCESS.ACTIONS.FIND_ACCESS, this.user);
      this.key++;
    }

    this.verifyTheme();
    this.$eventBus.$on('snackbar-message', this.showSnackbar);
    this.$eventBus.$on('show-loading', this.showLoading);
    this.$eventBus.$on('token-changed', this.getUser);

    this.startSocket();
  },
  beforeDestroy() {
    // Fechar a conexão quando o componente for destruído
    if (this.socket) {
      this.socket.close();
    }
  },
  computed: {
    ...mapGetters({
      getAccess: OPTIONS_STORE_ACCESS.GETTERS.GET_ACCESS
    })
  },
  methods: {
    startSocket() {
      // eslint-disable-next-line no-debugger

      const intervalId = setInterval(() => {
        if (!this.user.id) return;

        clearInterval(intervalId);

        let baseApi = new BaseApi();

        if (this.socket) return;

        this.socket = socketIOClient(baseApi.ipSocket, {
          query: {
            userId: this.user.id,
            userName: this.user.name,
            userEmail: this.user.email
          }
        });

        // Ouvir mensagens do servidor
        this.socket.on('handshack', (socketData) => {
          this.interpretMessage(socketData);
        });

        // Evento de desconexão
        this.socket.on('disconnect', () => {
          console.log('Desconectado do servidor');
        });
      }, 5000);
    },

    /*
     * Metodo que vai saber receber a mensagem e saber delegar para quem for necessário
     */
    interpretMessage(data) {
      let socketData = new SocketData(data);
      console.log(socketData);
    },

    verifyTheme() {
      this.$vuetify.theme.dark = utilsStorage.getThemeSettings();
    },
    showLoading(loading) {
      this.loading = loading;
    },
    showSnackbar(snackbar) {
      this.type = snackbar.type;
      this.message = snackbar.message;
      this.snackbar = true;
      this.timeout = snackbar.timeout || 5000;
    },

    shoudShowMenu() {
      const FORMS_DONT_SHOW_MENU = [
        'Login',
        'FormEditCustomerExternalAcess',
        'FormEditCorrespondentExternalAcess',
        'FileView',
        'FormCustomerFinally',
        'CustomerDocumentsExternalAccess',
        'OperationDocumentsExternalAccess'
      ];

      return !FORMS_DONT_SHOW_MENU.includes(this.$router.currentRoute.name);
    },
    shoudShowSimulator() {
      return !!(
        this.$router.currentRoute.meta.requireAuth &&
        this.isOpenSimulator &&
        this.$router.currentRoute.name != 'FileView'
      );
    }
  }
};
</script>
<style lang="scss">
.versao {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0px 15px 15px 5px;
}

.v-tabs .v-tab {
  text-transform: none;
}

.footer {
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
</style>
