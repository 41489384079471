export default class CorbanNationalBankModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.bank = parseInt(obj.bank) || undefined;
    this.name = obj.name;
    this.agency = obj.agency;
    this.account = obj.account;
    this.digit = obj.digit;
    this.pix = obj.pix;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}