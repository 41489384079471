/* Devem ser únicos */
const SEND_EMAIL_TRIGGER_CODES = {
  CLIENTE_ATUALIZACAO_FICHA_CADASTRAL: 'CLIENTE_ATUALIZACAO_FICHA_CADASTRAL',
  CLIENTE_PENDENCIA_CADASTRAL: 'CLIENTE_PENDENCIA_CADASTRAL',
  CLIENTE_SOLICITA_DIDUE: 'CLIENTE_SOLICITA_DIDUE',
  CLIENTE_APROVACAO_LIMITE: 'CLIENTE_APROVACAO_LIMITE',
  CLIENTE_CADASTRO_FINALIZADO: 'CLIENTE_CADASTRO_FINALIZADO',
  CLIENTE_CADASTRO_CONTA_EXTERIOR: 'CLIENTE_CADASTRO_CONTA_EXTERIOR',
  OPERACAO_FECHAMENTO: 'OPERACAO_FECHAMENTO',
  OPERACAO_ENVIO_CONTRATO_CAMBIO: 'OPERACAO_ENVIO_CONTRATO_CAMBIO',
  OPERACAO_ENVIO_SWIFT: 'OPERACAO_ENVIO_SWIFT',
  OPERACAO_AGRADECIMENTO_INDICACAO: 'OPERACAO_AGRADECIMENTO_INDICACAO',
  OPERACAO_ENTREGA_DIDUE: 'OPERACAO_ENTREGA_DIDUE',
  OPERACAO_PENDENCIA: 'OPERACAO_PENDENCIA',
  BANCO_ENVIO_CADASTRO: 'BANCO_ENVIO_CADASTRO',
  BANCO_ATUALIZACAO_CADASTRAL: 'BANCO_ATUALIZACAO_CADASTRAL',
  CORBAN_RELATORIO_FECHAMENTO: 'CORBAN_RELATORIO_FECHAMENTO',
  CORBAN_CRIACAO_USUARIO: 'CORBAN_CRIACAO_USUARIO',
  USUARIO_ESQUECEU_SENHA: 'USUARIO_ESQUECEU_SENHA',
};



module.exports = {
  SEND_EMAIL_TRIGGER_CODES,
}