<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <h1 data-test="titulo-documentos-operacao">Documentos da operação</h1>
      <v-row
        class="pt-3"
        :key="key"
      >
        <v-col
          class="py-0"
          cols="12"
          md="12"
        >
          <v-divider
            class="py-3"
            data-test="divider-documentos"
          />
        </v-col>
        <template v-for="(document, i) in documents">
          <v-col
            class="py-0"
            cols="12"
            md="2"
            :key="'type' + i"
          >
            <v-autocomplete
              data-test="autocomplete-tipo-documento"
              label="Tipo"
              :items="documentTypes"
              item-text="description"
              item-value="id"
              v-model="documents[i].operationDocumentType"
              return-object
              clearable
              dense
              outlined
              v-disabled-icon-focus
              :disabled="!!(documents[i].document || documents[i].path)"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
            :key="'documents' + i"
          >
            <DocumentInput
              data-test="document-input"
              :description="getOperationalDocumentDescription(document)"
              v-model="documents[i]"
              :api="apiDocument"
              :operation-id="operation.id"
              :disabled="!documents[i].operationDocumentType?.id"
            >
              <template #append-outer>
                <v-btn
                  v-if="documents.length >= 2 && !(documents[i].document || documents[i].path)"
                  class="py-0"
                  icon
                  @click="removeDocument(i)"
                  text
                  data-test="botao-remover-documento"
                >
                  <i class="far fa-trash" />
                </v-btn>
                <v-btn
                  class="py-0"
                  icon
                  @click="insertDocument"
                  text
                  data-test="botao-adicionar-documento"
                >
                  <i class="far fa-plus" />
                </v-btn>
              </template>
            </DocumentInput>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Components
import DocumentInput from '@/components/comum/DocumentInput';

// APIs
import OperationDocumentApi from '@/api/operation/operation-documents-api';

// Constants
import { OPERATION_DOCUMENT_TYPE_LIST } from '@/constants/general-constants.js';

// Models
import OperationDocumentModel from '@/model/operation-document-model';

export default {
  name: 'OperationalDocuments',
  components: { DocumentInput },
  model: {
    prop: 'operationProp',
    event: 'onChange'
  },
  props: {
    operationProp: {
      type: Object,
      default: () => {}
    }
  },
  inject: ['appData'],
  data() {
    return {
      operation: this.operationProp,
      apiDocument: new OperationDocumentApi(this.appData.currentUser),
      documentTypes: OPERATION_DOCUMENT_TYPE_LIST,
      documents: [],
      key: 0
    };
  },
  mounted() {
    this.documents = this.operation.documents;

    if (this.documents.length == 0) {
      this.documents = [new OperationDocumentModel()];
    }
  },
  watch: {
    documents: {
      handler() {
        this.key = this.key + 1;
        this.updateDocuments();
      },
      deep: true
    }
  },
  methods: {
    insertDocument() {
      this.documents.push(new OperationDocumentModel());
      this.key = this.key + 1;
    },
    removeDocument(index) {
      if (this.documents.length > 1) {
        this.documents.splice(index, 1);
      }

      this.key = this.key + 1;
    },
    updateDocuments() {
      let documents = this.documents.filter((d) => d.document);

      documents.forEach((item) => {
        if (!item?.fileName && !!item?.document?.name) item.fileName = item.document.name;
      });

      this.operation.documents = [...documents];
      this.$emit('onChange', this.operation);
    },
    getOperationalDocumentDescription(document) {
      return document?.operationDocumentType?.id === 5 ? document?.description : false;
    }
  }
};
</script>

<style></style>
