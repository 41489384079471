<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Agente Indicador</h1>
        <v-divider />
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="d-flex align-end"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="openAddIndicatedModal"
            >
              <i class="far fa-plus" />
              Adicionar
            </v-btn>
          </v-col>
        
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>
        
          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="indicatedCorrespondents"
                :search="search"
                sort-by="id"
                sort-desc
                :items-per-page="paginationDefault"
                height="300px"
                fixed-header
              >
                <template #[`item.name`]="{ item }">
                  {{ item.name }}
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <span
                        v-if="item.externalId"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="fas pl-1 fa-tag tag-external-id" />
                      </span>
                    </template>
                    Indicador importado com ID externo {{ item.externalId }}
                  </v-tooltip>
                </template>
                <template #[`item.createdAt`]="{ item }">
                  {{ item.createdAt | date }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        text
                        @click="editIndicatedModal(item)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <i class="far fa-pencil" />
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Add Indicated Modal -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="80vw"
      max-width="80vw"
      v-model="addIndicatedModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Indicado</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-indicated"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <FormCorrespondent
                      is-indicated
                      :indicator-id="correspondent.id"
                      :is-update="update"
                      :update-id="updateId"
                      :afer-save-callback="updateIndicatedListAndCloseModal"
                      :cancel-callback="closeIndicatedModal"
                      :key="modalKey"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Apis
import CorbanApi from "@/api/correspondent/correspondent-api";

// Models
import CorrespondentModel from "@/model/correspondent-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

import dateUtils from "../../../../common/utils/date";
import { PAGINATION_DEFAULT } from '@/constants/general-constants';


export default {
  name: "CorrespondentIndicated",
  mixins: [mixinMessage],
  components: {
    /* https://v2.vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components */
    FormCorrespondent: () => import ('../../views/correspondents/FormCorrespondent.vue')
  },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: {
      type: Object,
    },
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
  },
  inject: ['appData'],
  data() {
    return {
      correspondent: this.correspondentProp,
      search: "",
      columns: [
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "Nome Fantasia", value: "fantasyName", align: "left", sortable: true },
        { text: "Email", value: "accessEmail", align: "left", sortable: true },
        { text: "Estado", value: "state", align: "left", sortable: true },
        { text: "Cidade", value: "city", align: "left", sortable: true },
        { text: "Criado Em", value: "createdAt", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      addIndicatedModal: false,
      update: false,
      indicatedCorrespondents: [],
      updateId: null,
      modalKey: 0,
      corbanApi: new CorbanApi(this.appData.currentUser),
      paginationDefault: PAGINATION_DEFAULT
    };
  },
  mounted() {
    this.getIndicatedCorrespondensList();
  },
  computed: {
    
  },
  methods: {
    getIndicatedCorrespondensList(){
      this.corbanApi
        .findIndicated(this.correspondent.id)
        .then((response) => {
          this.indicatedCorrespondents = response.data.map((i) => new CorrespondentModel(i));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    closeIndicatedModal() {
      this.addIndicatedModal = false;
    },
    
    updateIndicatedListAndCloseModal(indicated) {
      let index = this.indicatedCorrespondents.findIndex((i) => i.id == indicated.id);

      if (index == -1) {
        this.indicatedCorrespondents.push(indicated);
      } else {
        this.indicatedCorrespondents.splice(
          index,
          1,
          indicated
        );
      }

      this.addIndicatedModal = false;
    },

    openAddIndicatedModal() {
      this.modalKey++;
      this.update = false;
      this.updateId = null;
      this.addIndicatedModal = true;
    },

    editIndicatedModal(indicated) {
      this.modalKey++;
      this.update = true;
      this.updateId = indicated.id;
      this.addIndicatedModal = true;
    },
  }
};
</script>

<style></style>
