export default class InternationalBankModel {
  constructor(obj) {
    obj = obj || {};

    this.bank = obj.bank;
    this.swiftCodeBIC = obj.swiftCodeBIC;
    this.numberIBAN = obj.numberIBAN;
    this.ownerName = obj.ownerName;
    this.tabNumberRouting = obj.tabNumberRouting;
    this.accountName = obj.accountName;
    this.intermediary = obj.intermediary;
    this.intermediaryCountry = obj.intermediaryCountry;
    this.intermediaryBankName = obj.intermediaryBankName;
    this.intermediaryAbaSwiftCode = obj.intermediaryAbaSwiftCode;
    this.forFurtherCredit = obj.forFurtherCredit;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}