<template>
  <div>
    <v-autocomplete
      label="Moeda"
      :items="sortedCurrencyList"
      v-model="selectedCurrency"
      item-value="id"
      :item-text="itemTextCurrencies"
      return-object
      dense
      clearable
      outlined
      v-disabled-icon-focus
      :rules="required ? [(v) => (!!v && !!v.id) || 'Moeda obrigatória'] : []"
      :no-data-text="noDataText"
      :hide-details="hideDetails"
    />
  </div>
</template>

<script>
export default {
  name: 'CurrencySelect',
  props: {
    currencyList: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCurrency: this.value
    };
  },
  computed: {
    itemTextCurrencies() {
      return (item) => item && item.code + ' - ' + item.name;
    },
    sortedCurrencyList() {
      if (!this.currencyList) return [];

      return [...this.currencyList].sort((a, b) => b.priority - a.priority);
    },
    noDataText() {
      return !this.currencyList || !this.currencyList.length ? 'Nenhuma moeda disponível' : '';
    }
  },
  watch: {
    selectedCurrency(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.selectedCurrency = newValue;
    }
  }
};
</script>
