<template>
  <div class="box">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <h1 class="titulo">Cadastro de banco</h1>
            <v-divider />
          </v-col>
        </v-row>

        <v-row 
          no-gutters
          class="py-2"
        >
          <v-col
            cols="12"
            md="9"
            sm="8"
            align-self="center"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="addBank"
            >
              <i class="far fa-plus" />
              Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="4"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
              class="mt-0 pt-2"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="banks"
                :search="search"
                sort-by="id"
                sort-desc
                :items-per-page="paginationDefault"
                :footer-props="itemsPerPageOptions"
                height="calc(100dvh - 310px)"
                fixed-header
              >
                <template #[`item.companyName`]="{ item }">
                  {{ item.companyName }}
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <span
                        v-if="item.externalId"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="fas pl-1 fa-tag tag-external-id" />
                      </span>
                    </template>
                    Banco importado com ID externo {{ item.externalId }}
                  </v-tooltip>
                </template>
                <template #[`item.createdAt`]="{ item }">
                  {{ item.createdAt | date }}
                </template>
                <template #[`item.active`]="{ item }">
                  <v-chip
                    small
                    label
                    :color="item.active ? 'success' : 'error'"
                  >
                    {{ item.active ? 'Ativo' : 'Inativo' }}
                  </v-chip>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="editBank(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-pencil" />
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// Apis
import ExchangeBankApi from "@/api/exchangeBank/exchange-bank-api";

// Models
import ExchangeBankModel from "@/model/exchange-bank-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Utils
import utilsStorage from "@/utils/storage";

// Tracking
import mixpanel from "mixpanel-browser";

// Utils
import dateUtils from "../../../../../common/utils/date";
import { PAGINATION_DEFAULT, ITEMS_PER_PAGE_DEFAULT } from '../../../constants/general-constants';

export default {
  name: "ExchangeBankControl",
  mixins: [mixinMessage],
  inject: ['appData'],
  data() {
    return {
      loading: true,
      search: "",
      user: utilsStorage.getUserOnStorage(),
      banks: [],
      columns: [
        { text: "Data de cadastro", value: "createdAt", align: "left", sortable: true, width: "150px" },
        { text: "Razão social", value: "companyName", align: "left", sortable: true },
        { text: "Nome fantasia", value: "fantasyName", align: "left", sortable: true },
        { text: "CNPJ", value: "cnpj", align: "left", sortable: true, width: "170px" },
        { text: "Email", value: "firstRegisterEmail", align: "left", sortable: true },
        { text: "Status", value: "active", align: "center", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      paginationDefault: PAGINATION_DEFAULT,
      itemsPerPageOptions: ITEMS_PER_PAGE_DEFAULT,
      exchangeBankApi: new ExchangeBankApi(this.appData.currentUser),
    };
  },
  mounted() {
    this.getBankList();
    mixpanel.track("page_view", {
      name_of_page_viewed: "banks_control",
      user_id: this.user.id,
    });
  },

  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
  },

  methods: {
    getBankList() {
      this.exchangeBankApi
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(error.response?.data?.mensagem || error, "error");
          this.loading = false;
        });
    },

    addBank() {
      this.$router.push({
        name: "ExchangeFormCreateBank",
      });
    },

    editBank(bank) {
      mixpanel.track("click", {
        button_name: "edit_bank",
      });
      this.$router
        .push({
          name: "ExchangeFormEditBank",
          params: {
            id: bank.id,
          },
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped></style>
