<template>
  <div :style="{ maxWidth: maxWidth}">
    <v-autocomplete
      :label="label"
      :items="listOfUFs"
      clearable
      dense
      v-model="ufData"
      outlined
      hint="Este campo é obrigatório"
      hide-selected
      v-disabled-icon-focus
      :rules="rules"
    />
  </div>
</template>

<script>
// API
import apiIBGE from "@/api/general/ibge-api";

export default {
  name: "UFField",
  model: {
    prop: "entrada",
    event: "onChange",
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    entrada: { type: String, default: undefined },
    rules: { type: Array, default: () => [] },
    maxWidth: { type: String, default: '-1'}
  },
  data() {
    return {
      listOfUFs: undefined,
      ufData: this.entrada,
    };
  },
  mounted() {
    this.getUF();
  },
  watch: {
    ufData() {
      this.$emit("onChange", this.ufData);
    },
    entrada(){
      this.ufData = this.entrada;
    }
  },
  computed: {
    mediumScreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  methods: {
    getUF() {
      apiIBGE.getState().then((resposta) => {
        this.listOfUFs = resposta.data.map((uf) => uf.sigla);
      });
    },
  },
};
</script>
