<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card
        class="box card-cambio"
        :class="operation.exchangeBuy ? 'isBuy' : 'isSell'"
        data-test="card-operacao-cambio"
      >
        <v-row class="pt-4">
          <v-col
            cols="12"
            md="3"
            class="py-0 my-0"
          >
            <v-radio-group
              class="py-0 my-0"
              v-model="operation.exchangeBuy"
              row
              mandatory
              data-test="radio-group-tipo-operacao"
            >
              <v-radio
                :value="false"
                label="Venda/Saída"
                data-test="radio-venda"
              />
              <v-radio
                :value="true"
                label="Compra/Entrada"
                data-test="radio-compra"
              />
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-cliente"
              label="Cliente / CPF / CNPJ"
              :items="customerList"
              v-model="operation.customer"
              item-value="id"
              :item-text="itemTextCustomers"
              return-object
              dense
              clearable
              autofocus
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
              :loading="customerSearchLoading"
              :search-input.sync="customerSearchQuery"
              hide-no-data
              cache-items
              :filter="caseInsensitiveAccentsInsensitiveIgnoreDotsDashes"
              ref="customerAutocomplete"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-cidade"
              label="Cidade"
              :items="exchangeStoreCities"
              v-model="operation.storeCity"
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteText]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-banco"
              label="Banco"
              :items="bankList"
              v-model="operation.bank"
              item-value="id"
              :item-text="itemTextBanks"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId, validationBankHasLimit]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-loja"
              label="Loja"
              :items="storeList"
              v-model="operation.store"
              item-value="id"
              :item-text="itemTextStores"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-natureza"
              label="Natureza"
              :items="natureList"
              v-model="operation.nature"
              item-value="id"
              item-text="exibitionName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-forma-entrega"
              label="Forma de entrega"
              :items="deliveryTypeList"
              v-model="operation.deliveryType"
              item-value="id"
              item-text="description"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <CurrencySelect
              data-test="currency-select-moeda"
              :currencyList="currencyList"
              v-model="operation.currency"
              :required="true"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-forma-pagamento"
              label="Forma de pagamento"
              :items="methodList"
              v-model="operation.paymentMethod"
              item-value="id"
              item-text="name"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-quantidade-me"
              label="Quantidade ME"
              prefix=" "
              v-model="operation.amount"
              :rules="[validationIsRequiredFieldNumeric, validationOperationHasLimit]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-taxa-cliente"
              label="Taxa do cliente"
              prefix="R$ "
              suffix=""
              :precision="operation.currency.precision"
              v-model="operation.customerRate"
              persistent-hint
              :hint="customerRateHint"
              :min="operation.customerRateMin"
              :max="operation.customerRateMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-spread"
              label="Spread"
              prefix=" "
              suffix=" %"
              v-model="operation.spread"
              persistent-hint
              :hint="spreadHint"
              :min="operation.spreadMin"
              :max="operation.spreadMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-lucro"
              label="Lucro"
              :precision="2"
              disabled
              v-model="profit"
              persistent-hint
            />
          </v-col>
          <v-col
            v-if="operation.deliveryType.id == 2"
            cols="12"
            md="2"
            class="py-0"
          >
            <MoneyField
              data-test="moneyfield-custo-entrega"
              label="Custo de Entrega"
              prefix=" "
              v-model="operation.deliveryCost"
              :rules="[validationIsRequiredFieldNumericAllowZero]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <Datepicker
              data-test="datepicker-data-entrega"
              v-model="operation.deliveryDate"
              :label="operation.deliveryType.id == 1 ? 'Data de retirada' : 'Data de entrega'"
              v-disabled-icon-focus
              :data-minima="new Date().toISOString()"
              :rules="[validationIsRequiredField]"
              :allowed-dates="isAllowedDate"
              :data-maxima="maxDate"
            />
          </v-col>
          <v-col
            v-if="operation.deliveryType.id == 2"
            cols="12"
            md="2"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-periodo-entrega"
              label="Período"
              :items="deliveryTimeList"
              v-model="operation.deliveryTime"
              item-value="id"
              item-text="description"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="py-0"
          >
            <Datepicker
              data-test="datepicker-data-viagem"
              v-model="operation.travelDate"
              label="Data de Viagem"
              v-disabled-icon-focus
              :data-minima="new Date().toISOString()"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-conta"
              :label="operation.exchangeBuy ? 'Conta cliente' : 'Conta banco'"
              :items="accountList"
              v-model="account"
              item-value="id"
              :item-text="itemTextAccount"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-textarea
              data-test="textarea-observacoes"
              auto-grow
              outlined
              rows="1"
              v-model="operation.observation"
              label="Observações"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              data-test="autocomplete-corban"
              label="Corban"
              :items="agentList"
              v-model="operation.agent"
              item-value="id"
              item-text="name"
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import MoneyField from '@/components/comum/MoneyField';
import Datepicker from '@/components/comum/Datepicker.vue';
import CurrencySelect from './CurrencySelect.vue';

// Api
import CustomerApi from '@/api/customer/customer-api';
import apiDeliveryType from '@/api/exchangeStore/delivery-type-api';
import apiDeliveryTime from '@/api/exchangeStore/delivery-time-api';
import CorbanApi from '@/api/correspondent/correspondent-api';
import apiPaymentMethod from '@/api/configuration/payment-method-api';

// Cache
import bankCache from '../../../../common/cache/bank-cache';

// Models
import OperationModel from '@/model/operation-model';
import CustomerModel from '@/model/customer-model';
import CustomerExchangeBankModel from '@/model/customer-exchange-bank-model';
import DeliveryTypeModel from '@/model/delivery-type-model';
import DeliveryTimeModel from '@/model/delivery-time-model';
import CorrespondentModel from '@/model/correspondent-model';
import PaymentMethodModel from '@/model/payment-method-model';
import OperationCustomerAccountModel from '@/model/operation-customer-account-model';
import CustomerFiltersModel from '@/model/customer/customer-filters-model';

// Mixins
import mixinValidationRules from '@/mixin/mixin-validation-rules';
import mixinAuthorization from '@/mixin/mixin-authorization';
import mixinMessage from '@/mixin/mixin-message';
import mixinAutoCompleteFilters from '@/mixin/mixin-autocomplete-filters';

// Utils
import numberUtils from '../../../../common/utils/number';
import dateUtils from '../../../../common/utils/date';
import operationUtils from '@/utils/operation';

// Constants
import { DELIVERY_TYPE, OPERATION_TYPE } from '@/constants/general-constants';
import { CUSTOMER_WORKFLOW } from '../../../../common/workflows/customersWorkflow';

export default {
  name: 'OperationExchange',
  mixins: [mixinValidationRules, mixinAuthorization, mixinMessage, mixinAutoCompleteFilters],
  components: { MoneyField, Datepicker, CurrencySelect },
  inject: ['appData'],
  data() {
    return {
      operation: this.operationProp,
      customerList: [],
      exchangeBankList: [],
      deliveryTypes: [],
      deliveryTimes: [],
      agentList: [],
      methodList: [],
      account: {},
      customer: new CustomerModel(),
      listOfBanks: [],
      validationOperationHasLimit: true,
      customerSearchTimer: undefined,
      customerSearchQuery: '',
      customerSearchLoading: false,
      corbanApi: new CorbanApi(this.appData.currentUser),
      customerApi: new CustomerApi(this.appData.currentUser)
    };
  },
  model: {
    prop: 'operationProp',
    event: 'onChange'
  },
  props: {
    operationProp: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    operation() {
      this.emitOperation();
    },
    account() {
      this.emitOperation();
    },
    natureList() {
      if (this.natureList.length == 1) {
        this.operation.nature = this.natureList[0];
      }
    },
    exchangeBankList() {
      this.mountOperationUpdate();
      this.mountOperationFromQueryString();
    },
    'operation.customer'() {
      if (this.operation.customer == undefined) {
        this.operation = new OperationModel({
          exchangeType: this.operation.exchangeType,
          exchangeBuy: this.operation.exchangeBuy
        });
        this.customer = new CustomerModel();

        return;
      }

      /* This prevents the constant api request of the same customer */
      if (
        this.operation.customer.id != undefined &&
        this.operation.customer.id == this.customer.id
      ) {
        return;
      }

      this.getExchangeBanks();
      this.operation.agent = this.operation.customer.correspondent;
      this.getCustomer();
    },
    'operation.reducedLimit'() {
      this.validationOperationHasLimit = operationUtils.validationOperationHasLimit(
        this.operation,
        this.exchangeBankList
      );
    },
    'operation.storeCity'() {
      this.mountOperationFromQueryString();
    },
    customerSearchQuery() {
      clearTimeout(this.customerSearchTimer);

      if (this.customerSearchQuery == this.itemTextCustomers(this.customer)) {
        return;
      }

      if ((this.customerSearchQuery?.length || 0) < 3) {
        this.customerList = [];

        return;
      }

      this.customerSearchTimer = setTimeout(() => {
        this.customerSearchLoading = true;
        this.getCustomersList().then(() => {
          this.customerSearchLoading = false;
        });
      }, 500);
    }
  },
  async mounted() {
    this.getDeliveryTypes();
    this.getDeliveryTimes();
    this.getagentList();
    this.getMethodList();

    if (this.operation.customer.id) {
      this.getExchangeBanks();
      this.getCustomer();
    }

    this.listOfBanks = await bankCache.getBankList();
  },
  computed: {
    profit() {
      if (!this.operationProp.totalValue || !this.operation.spread) return 0;

      return this.operationProp.totalValue * (this.operation.spread / 100);
    },
    itemTextCustomers() {
      return (item) =>
        !item.name ? item.companyName + ' - ' + item.cpfCnpj : item.name + ' - ' + item.cpfCnpj;
    },
    exchangeStoreCities() {
      let flattened = this.exchangeBankList
        .map((i) => i.bank.exchangeStores.map((j) => j.city))
        .flat();

      return Array.from(new Set(flattened));
    },
    bankList() {
      return (
        this.exchangeBankList
          .filter(
            (i) => i.bank.exchangeStores.filter((j) => j.city == this.operation.storeCity).length
          )
          .map((k) => k.bank)
          .filter(
            (b) =>
              b.exchangeType.id == this.operation.exchangeType.id ||
              b.exchangeType.id == OPERATION_TYPE.BOTH.id
          ) || []
      );
    },
    itemTextBanks() {
      return (item) => {
        let limit = this.exchangeBankList
          .filter((i) => i.bank.id == item.id)
          .map((j) => j.limit)
          .pop();

        let remainingLimit = 0;

        if (limit?.isInOut) {
          remainingLimit =
            (this.operation.exchangeBuy ? limit.remainingIn : limit.remainingOut) || 0;
        } else {
          remainingLimit = limit?.remaining || 0;
        }

        let formatedRemaining = item.useUsdLimit
          ? this.formatUSD(remainingLimit)
          : this.formatBRL(remainingLimit);
        let formatedExpire = dateUtils.maskDateIso(limit?.expireAt);

        return item.fantasyName + ' - ' + formatedRemaining + ' - ' + formatedExpire;
      };
    },
    storeList() {
      return (
        this.bankList
          .filter((i) => i.id == this.operation.bank.id)
          .map((j) => j.exchangeStores)
          .flat()
          .filter((k) => k?.city == this.operation.storeCity) || []
      );
    },
    itemTextStores() {
      return (item) => item.fantasyName;
    },
    natureList() {
      return (
        this.exchangeBankList
          .filter((i) => i.bank.id == this.operation.bank.id)
          .map((j) => j.operationNatures)
          .flat() || []
      );
    },
    deliveryTypeList() {
      let type =
        this.storeList
          .flat()
          .filter((i) => i.id == this.operation.store.id)
          .map((j) => j.deliveryType)
          .pop() || {};

      if (type.id == null) {
        return [];
      }

      if (type.id == 3) {
        return this.deliveryTypes.filter((i) => i.id != 3);
      }

      return [type];
    },
    itemTextCurrencies() {
      return (item) => item.code + ' - ' + item.name;
    },
    currencyList() {
      return (
        this.storeList
          .filter((i) => i.id == this.operation.store.id)
          .map((j) => j.exchangeSpreads)
          .flat()
          .map((k) => k.currency) || []
      );
    },
    deliveryTimeList() {
      let time =
        this.storeList
          .flat()
          .filter((i) => i.id == this.operation.store.id)
          .map((j) => j.deliveryTime)
          .pop() || {};

      if (time.id == null) {
        return [];
      }

      if (time.id == 3 || time.id == 4) {
        return this.deliveryTimes.filter((i) => i.id != 3 && i.id != 4);
      }

      return [time];
    },
    spreadHint() {
      if (this.operation.spreadMin && this.operation.spreadMax) {
        return `Min: ${this.operation.spreadMin}% - Max: ${this.operation.spreadMax}%`;
      }

      return '';
    },
    customerRateHint() {
      if (this.operation.customerRateMin && this.operation.customerRateMax) {
        return `Min: ${this.formatBRL(this.operation.customerRateMin, this.operation.currency.precision)} - Max: ${this.formatBRL(
          this.operation.customerRateMax,
          this.operation.currency.precision
        )}`;
      }

      return '';
    },
    accountList() {
      let accountList = [];

      if (!this.operation.exchangeBuy) {
        if (this.operation.bank.paymentAccount) {
          accountList = this.operation.customer.paymentAccounts.filter(
            (i) => i.bank == this.operation.bank.linkedBank
          );
        } else {
          accountList = [this.operation.bank];
        }
      } else {
        accountList = this.operation.customer.nationalBanks;
      }

      accountList.map((i) => {
        let bank = this.listOfBanks.find((b) => b.code == i.bank);
        i.bankName = bank?.name;

        return i;
      });

      return accountList;
    },
    itemTextAccount() {
      return (item) =>
        'Banco: ' +
        item.bankName +
        ' - Agência: ' +
        item.agency +
        ' - Conta: ' +
        item.account +
        ' - Dígito: ' +
        item.digit;
    },
    maxDate() {
      if (
        !this.operation.store?.id ||
        this.operation.deliveryType.id == DELIVERY_TYPE.DELIVERY.id
      ) {
        return undefined;
      }

      let withdrawalDateTime = this.operation.store.withdrawalDate.description.split(' ')[0];
      let date = dateUtils.addBusinessDay(new Date(), withdrawalDateTime);

      return new Date(date).toISOString();
    }
  },
  methods: {
    getCustomersList() {
      let filters = new CustomerFiltersModel({
        registerStatusList: [CUSTOMER_WORKFLOW.CADASTRO_APROVADO.id],
        searchText: this.customerSearchQuery
      });

      return this.customerApi
        .findAll(filters)
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getExchangeBanks() {
      this.customerApi
        .findExchangeBanks(this.operation.customer.id, this.operation.exchangeType.id)
        .then((response) => {
          this.exchangeBankList = response.data
            .map((c) => new CustomerExchangeBankModel(c))
            .filter((i) => i.limit.id);
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getDeliveryTypes() {
      if (this.deliveryTypes[0]) {
        return;
      }

      apiDeliveryType
        .findAll()
        .then((response) => {
          this.deliveryTypes = response.data.map((t) => new DeliveryTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getDeliveryTimes() {
      if (this.deliveryTimes[0]) {
        return;
      }

      apiDeliveryTime
        .findAll()
        .then((response) => {
          this.deliveryTimes = response.data.map((t) => new DeliveryTimeModel(t));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getagentList() {
      this.corbanApi
        .findAll()
        .then((response) => {
          this.agentList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getMethodList() {
      apiPaymentMethod
        .findAll()
        .then((response) => {
          this.methodList = response.data.map((m) => new PaymentMethodModel(m));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    formatBRL(value, precision) {
      return numberUtils.formatCurrency('BRL', value, precision);
    },
    formatUSD(value, precision) {
      return numberUtils.formatCurrency('USD', value, precision);
    },

    getCustomer() {
      this.customerApi
        .findId(this.operation.customer.id)
        .then((response) => {
          this.operation.customer = new CustomerModel(response.data);
          this.customer = new CustomerModel(response.data);

          if (this.$route.query.idCustomer) {
            this.customerList = [this.customer];

            this.$nextTick(() => {
              this.$refs.customerAutocomplete.isMenuActive = false;
            });
          }

          if (!this.operation.customer.correspondent.id) {
            this.sendMessage('Esse cliente não possui um corban!', 'error');

            return;
          }

          this.operation.agent = this.operation.customer.correspondent;
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    emitOperation() {
      let account = new OperationCustomerAccountModel(this.account);

      this.operation.account = account;
      this.$emit('onChange', this.operation);
    },

    validationBankHasLimit() {
      return operationUtils.validationOperationLimitNotExpired(
        this.operation,
        this.exchangeBankList
      );
    },

    mountOperationFromQueryString() {
      this.mountCityFromQuery();
      this.mountBankFromQuery();
      this.mountStoreFromQuery();
      this.mountNatureFromQuery();
      this.mountCurrencyFromQuery();
      this.mountDeliveryTypeFromQuery();
    },

    mountCityFromQuery() {
      if (this.$route.query.city) {
        let city = this.exchangeStoreCities.find((c) => c == this.$route.query.city);

        if (city) {
          this.operation.storeCity = city;
        }

        this.$route.query.city = undefined;
      }
    },

    mountBankFromQuery() {
      if (this.$route.query.idBank) {
        let bank = this.bankList.find((b) => b.id == this.$route.query.idBank);

        if (bank) {
          this.operation.bank = bank;
        }

        if (this.operation.storeCity) {
          this.$route.query.idBank = undefined;
        }
      }
    },

    mountStoreFromQuery() {
      if (this.$route.query.idStore) {
        let store = this.storeList.find((s) => s.id == this.$route.query.idStore);

        if (store) {
          this.operation.store = store;
        }

        this.$route.query.idStore = undefined;
      }
    },

    mountNatureFromQuery() {
      if (this.$route.query.idNature) {
        let nature = this.natureList.find((n) => n.id == this.$route.query.idNature);

        if (nature) {
          this.operation.nature = nature;
        }

        this.$route.query.idNature = undefined;
      }
    },

    mountCurrencyFromQuery() {
      if (this.$route.query.idCurrency) {
        let currency = this.currencyList.find((c) => c.id == this.$route.query.idCurrency);

        if (currency) {
          this.operation.currency = currency;
        }

        this.$route.query.idCurrency = undefined;
      }
    },

    mountDeliveryTypeFromQuery() {
      if (this.$route.query.idDeliveryType) {
        let deliveryType = this.deliveryTypeList.find(
          (d) => d.id == this.$route.query.idDeliveryType
        );

        if (deliveryType) {
          this.operation.deliveryType = deliveryType;
        }

        this.$route.query.idDeliveryType = undefined;
      }
    },

    mountOperationUpdate() {
      this.mountBankUpdate();
      this.mountCurrencyUpdate();
      this.mountAccountUpdate();
    },

    mountBankUpdate() {
      if (this.operation.bank?.id) {
        let bank = this.bankList.find((b) => b.id == this.operation.bank.id);

        if (bank) {
          this.operation.bank = bank;
        }
      }
    },

    mountCurrencyUpdate() {
      if (this.operation.currency?.id) {
        let currency = this.currencyList.find((c) => c.id == this.operation.currency.id);

        if (currency) {
          this.operation.currency = currency;
        }
      }
    },

    mountAccountUpdate() {
      if (this.operation.account?.id) {
        let operationAccount = this.operation.account;
        let account = this.accountList.find(
          (a) =>
            a.account == operationAccount.account &&
            a.agency == operationAccount.agency &&
            a.bank == operationAccount.bank
        );

        if (account) {
          this.account = account;
        }
      }
    },

    isAllowedDate(date) {
      let weekDays = this.getAllowedWeekDays();
      let currentDate = new Date(date);

      return weekDays.includes(currentDate.getUTCDay());
    },

    getAllowedWeekDays() {
      if (this.operation.deliveryType.id == DELIVERY_TYPE.WITHDRAWAL.id) {
        return this.getAllowedWeekDaysWithdrawal();
      }

      return this.getAllowedWeekDaysDelivery();
    },
    getAllowedWeekDaysDelivery() {
      let weekDays = [];

      if (this.operation.store.deliverySunday) {
        weekDays.push(0);
      }

      if (this.operation.store.deliveryMonday) {
        weekDays.push(1);
      }

      if (this.operation.store.deliveryTuesday) {
        weekDays.push(2);
      }

      if (this.operation.store.deliveryWednesday) {
        weekDays.push(3);
      }

      if (this.operation.store.deliveryThursday) {
        weekDays.push(4);
      }

      if (this.operation.store.deliveryFriday) {
        weekDays.push(5);
      }

      if (this.operation.store.deliverySaturday) {
        weekDays.push(6);
      }

      return weekDays;
    },
    getAllowedWeekDaysWithdrawal() {
      let weekDays = [];

      if (this.operation.store.withdrawalSunday) {
        weekDays.push(0);
      }

      if (this.operation.store.withdrawalMonday) {
        weekDays.push(1);
      }

      if (this.operation.store.withdrawalTuesday) {
        weekDays.push(2);
      }

      if (this.operation.store.withdrawalWednesday) {
        weekDays.push(3);
      }

      if (this.operation.store.withdrawalThursday) {
        weekDays.push(4);
      }

      if (this.operation.store.withdrawalFriday) {
        weekDays.push(5);
      }

      if (this.operation.store.withdrawalSaturday) {
        weekDays.push(6);
      }

      return weekDays;
    }
  }
};
</script>

<style>
.isBuy {
  border: 3px solid var(--v-colorBuy-base) !important;
}

.isSell {
  border: 3px solid var(--v-colorSell-base) !important;
}
</style>
