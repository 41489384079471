<template>
  <div
    v-if="!loading"
    class="py-2"
  >
    <AddressComponent
      data-test="address-residencial"
      address-name="Endereço residencial"
      v-model="customer.residentialAddress"
      required
    />
    <v-checkbox
      v-if="isExchange"
      data-test="ck-endereco-entrega"
      label="Deseja cadastrar outro endereço para entrega"
      v-model="deliveryCheck"
      dense
    />
    <AddressComponent
      v-if="deliveryCheck && isExchange"
      data-test="address-entrega"
      address-name="Endereço entrega"
      v-model="customer.deliveryAddress"
    />
  </div>
</template>

<script>
// Constants
import { ADDRESS_TYPE } from '../../../../../../common/constants/generic/types';
// Component
import AddressComponent from '@/components/comum/AddressComponent.vue';

// Models
import CustomerAddressModel from '@/model/customer-address-model';

export default {
  name: 'PFCustomerAddress',
  components: { AddressComponent },
  model: {
    prop: 'customerProp',
    event: 'onChange'
  },
  props: {
    customerProp: {
      type: Object
    },
    isExchange: {
      type: Boolean
    }
  },
  data() {
    return {
      deliveryCheck: false,
      customer: this.customerProp,
      loading: true
    };
  },
  computed: {},
  watch: {
    'customer.residentialAddress': {
      handler() {
        this.fillCustomerAddress();
      },
      deep: true
    },
    'customer.deliveryAddress': {
      handler() {
        this.fillCustomerAddress();
      },
      deep: true
    }
  },
  mounted() {
    this.mountAddress();
  },
  methods: {
    fillCustomerAddress() {
      let addresses = [];

      // Done this way because Object.assign was generating an infinite loop
      let residentialJSON = JSON.stringify(this.customer.residentialAddress);
      let residential = JSON.parse(residentialJSON);
      residential.addressType = [];
      residential.addressType.push(ADDRESS_TYPE.RESIDENTIAL);

      addresses.push(residential);

      if (!this.deliveryCheck) {
        residential.addressType.push(ADDRESS_TYPE.DELIVERY);
      } else {
        let deliveryJSON = JSON.stringify(this.customer.deliveryAddress);
        let delivery = JSON.parse(deliveryJSON);
        delivery.addressType = [];
        delivery.addressType.push(ADDRESS_TYPE.DELIVERY);
        addresses.push(delivery);
      }

      this.customer.addresses = addresses;
      this.$emit('onChange', this.customer);
    },
    mountAddress() {
      if (this.customer.addresses.length > 0) {
        let addresses = this.customer.addresses;
        for (let i = 0; i < addresses.length; i++) {
          const address = addresses[i];

          for (let j = 0; j < address.addressType.length; j++) {
            const type = address.addressType[j];

            if (type.id == ADDRESS_TYPE.RESIDENTIAL.id) {
              this.customer.residentialAddress = new CustomerAddressModel(address);
            }

            if (type.id == ADDRESS_TYPE.DELIVERY.id) {
              this.customer.deliveryAddress = new CustomerAddressModel(address);
            }
          }
        }

        if (this.customer.residentialAddress.id == this.customer.deliveryAddress.id) {
          this.customer.deliveryAddress = new CustomerAddressModel();
          this.deliveryCheck = false;
        } else {
          this.deliveryCheck = true;
        }

        this.$emit('onChange', this.customer);
      }

      this.loading = false;
    }
  }
};
</script>

<style></style>
