<template>
  <div class="ma-2 pt-2 pt-md-0 ma-md-5">
    <v-row
      no-gutters
      class="pb-2"
      v-if="!loading"
      :key="panelKey"
    >
      <v-col
        cols="12"
        md="9"
      >
        <v-row no-gutters>
          <v-col cols="12">
            <v-card class="box card-cambio">
              <v-row no-gutters>
                <v-col cols="6">
                  <h1 data-test="titulo-operacoes">Operações</h1>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    text
                    class="btn-secondary float-right"
                    @click="editCustomer"
                    :disabled="!operation.customer.id"
                    data-test="botao-editar-cliente"
                  >
                    <i class="far fa-user" />
                    Editar cliente
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider />
              <v-spacer />
              <v-radio-group
                v-model="operation.exchangeType.id"
                row
                data-test="radio-group-tipo-cambio"
                hide-details
              >
                <v-radio
                  v-for="(item, i) in filteredExchangeTypes"
                  :key="i"
                  :value="item.id"
                  :label="item.description"
                  data-test="radio-tipo-cambio"
                />
              </v-radio-group>
            </v-card>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          v-if="operation.exchangeType.id"
          class="pt-2 pt-md-5"
        >
          <v-col cols="12">
            <v-form
              ref="formOperation"
              v-model="valid"
              data-test="form-operacao"
            >
              <div v-if="operation.exchangeType.id == 2">
                <OperationExchange v-model="operation" />
                <OperationExchangeDeliveryAddress
                  v-if="operation.deliveryType.id == 2 && !operation.exchangeBuy"
                  v-model="operation"
                />
              </div>
              <div v-else>
                <OperationRemittance v-model="operation" />
                <OperationDocuments v-model="operation" />
              </div>
            </v-form>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="pt-2"
        >
          <v-col 
            cols="12"
            v-administrative-access
            class="d-flex justify-space-between justify-md-end"
          >
            <v-btn
              class="btn-primary mr-2"
              @click="openSaveOperationModal"
              text
              data-test="botao-salvar-admin"
            >
              Salvar
            </v-btn>
            <v-btn
              class="btn-secondary mx-2 "
              @click="createOpenOperation"
              text
              data-test="botao-salvar-pre-venda-admin"
            >
              {{ this.editMode ? 'Atualizar pré-venda' : 'Salvar pré-venda' }}
            </v-btn>
            <v-btn
              class="btn-tertiary"
              @click="cancel()"
              text
              data-test="botao-cancelar-admin"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            v-corban-access
          >
            <v-btn
              class="btn-primary mx-2 float-right"
              @click="createOpenOperation"
              text
              data-test="botao-salvar-corban"
            >
              Salvar dfd
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="3"
        class="pt-2 pt-md-0 pl-md-4"
      >
        <OperationPreview v-model="operation" />
      </v-col>
    </v-row>

    <!-- Save Operation Modal -->
    <v-dialog
      persistent
      max-width="600px"
      v-model="saveOperationModal"
      data-test="dialog-salvar-operacao"
    >
      <v-card>
        <v-card-title data-test="titulo-salvar-operacao">
          <h1 class="titulo-modal">Taxa do banco</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-operation"
                ref="formBankRate"
                data-test="form-taxa-banco"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <MoneyField
                      label="Taxa do Banco"
                      prefix="R$ "
                      v-model="operation.bankRate"
                      :precision="operation.currency.precision"
                      :rules="[validationIsRequiredFieldNumeric]"
                      autofocus
                      data-test="money-field-taxa-banco"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions data-test="acoes-salvar-operacao">
          <v-col
            cols="6"
            class="text-left px-0"
          >
            <v-btn
              class="btn-secondary mx-2 float-left"
              @click="copyBankMessageToClipboard"
              text
              data-test="botao-copiar-simulacao"
            >
              Copiar Simulação do Banco
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            class="text-right"
          >
            <v-btn
              text
              class="btn-primary mr-2"
              @click="createOperation"
              data-test="botao-fechar-negocio"
            >
              Fechar Negócio
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="cancelCreateOperation"
              data-test="botao-cancelar-fechar-negocio"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation-window
      v-model="openConfirmationWindow"
      :callback-primary="callbackPrimary"
      :message="`Há dados preenchidos, deseja realmente cancelar ${
        editMode ? 'a edição?' : 'o cadastro?'
      }`"
      :text-primary-button="'Sim, cancelar'"
      :text-secondary-button="'Não'"
      data-test="tela-confirmacao"
    />
  </div>
</template>

<script>
// Components
import OperationExchange from '@/components/form-operation/OperationExchange.vue';
import OperationPreview from '@/components/form-operation/OperationPreview.vue';
import OperationExchangeDeliveryAddress from '@/components/form-operation/OperationExchangeDeliveryAddress.vue';
import OperationRemittance from '@/components/form-operation/OperationRemittance.vue';
import OperationDocuments from '@/components/form-operation/OperationDocuments.vue';
import MoneyField from '@/components/comum/MoneyField.vue';
import ConfirmationWindow from '@/components/comum/ConfirmationWindow';

// Apis
import ExchangeBankTypeApi from '@/api/exchangeBank/exchange-bank-type-api';
import OperationApi from '@/api/operation/operation-api';
import OperationDocumentApi from '@/api/operation/operation-documents-api';
import SketchApi from '@/api/general/sketch-api';
import TriggerApi from '@/api/generic/trigger-api';

// Mixins
import mixinMessage from '@/mixin/mixin-message';
import mixinNestedFormValidation from '@/mixin/mixin-nested-form-validation';
import mixinValidationRules from '@/mixin/mixin-validation-rules';

// Models
import OperationModel from '@/model/operation-model';
import ExchangeBankTypeModel from '@/model/exchange-bank-type-model';
import OperationPaymentAccountModel from '@/model/operation-payment-account-model';
import SketchModel from '@/model/general/sketch-model';
import TriggerModel from '@/model/generic/trigger-model';

// Tracking
import mixpanel from 'mixpanel-browser';

// Utils
import operationMessagesTemplate from '@/utils/operationMessagesTemplate';

// Constants
import { TYPES } from '../../../../common/constants/generic/sketches';
import { OPERATION_TYPE } from '../../../../common/constants/generic/types';
import { SEND_EMAIL_TRIGGER_CODES } from '../../../../common/constants/generic/triggers';
import { OPERATION_WORKFLOW } from '../../../../common/workflows/operationWorkflow';

export default {
  name: 'FormOperation',
  mixins: [mixinMessage, mixinNestedFormValidation, mixinValidationRules],
  components: {
    OperationExchange,
    ConfirmationWindow,
    OperationPreview,
    OperationExchangeDeliveryAddress,
    OperationRemittance,
    OperationDocuments,
    MoneyField
  },
  inject: ['appData'],
  data() {
    return {
      operation: new OperationModel(),
      exchangeTypes: [],
      saveOperationModal: false,
      loading: false,
      editMode: false,
      openConfirmationWindow: false,
      valid: false,
      panelKey: 0,
      apiExchangeBankType: new ExchangeBankTypeApi(this.appData.currentUser),
      triggerApi: new TriggerApi(this.appData.currentUser),
      sketchApi: new SketchApi(this.appData.currentUser),
      operationApi: new OperationApi(this.appData.currentUser),
      operationDocumentApi: new OperationDocumentApi(this.appData.currentUser)
    };
  },
  mounted() {
    this.getExchangeTypes();

    if (this.$route.params.id) {
      this.loading = true;
      this.getOperationBySketchId(this.$route.params.id);
      this.editMode = true;
    } else if (this.$route.query.idExchangeType) {
      this.mountOperationFromQueryString();
    } else {
      this.editMode = false;
    }
  },
  computed: {
    filteredExchangeTypes() {
      let filtered = this.exchangeTypes.filter((i) => i.id != 3);

      return filtered;
    }
  },
  watch: {
    'operation.exchangeBuy'() {
      this.resetOperation();
    },
    'operation.exchangeType.id'() {
      this.resetOperation();
    },
    'operation.customer'() {
      if (this.operation.bank.paymentAccount) {
        let account = this.operation.customer.paymentAccounts.find(
          (a) => a.bank == this.operation.bank.linkedBank
        );
        this.operation.paymentAccount = new OperationPaymentAccountModel({
          paymentAccount: true,
          ...account
        });
      } else {
        this.operation.paymentAccount = new OperationPaymentAccountModel(this.operation.bank);
      }
    },
    'operation.bank'() {
      if (this.operation.bank.paymentAccount) {
        let account = this.operation.customer.paymentAccounts.find(
          (a) => a.bank == this.operation.bank.linkedBank
        );
        this.operation.paymentAccount = new OperationPaymentAccountModel({
          paymentAccount: true,
          ...account
        });
      } else {
        this.operation.paymentAccount = new OperationPaymentAccountModel(this.operation.bank);
      }
    }
  },
  methods: {
    getExchangeTypes() {
      this.apiExchangeBankType
        .findAll()
        .then((response) => {
          if (response.data.length == 0) {
            this.sendMessage(
              'O seu usuário não está configurado para operar, por favor, entre em contato com o responsável pelo sistema.',
              'warning'
            );
          }

          this.exchangeTypes = response.data.map((t) => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    getOperationBySketchId(id) {
      this.sketchApi
        .findId(id)
        .then((response) => {
          let sketch = response.data;
          this.operation = new OperationModel(JSON.parse(sketch.content));

          this.operation.documents.forEach((item) => {
            item.document = this.convertBase64ToFile(item.document, item.fileName);
          });

          this.operation.sketchId = sketch.id;
          this.$nextTick(() => {
            this.loading = false;
          });
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
          this.loading = false;
        });
    },

    convertBase64ToFile(base64String, fileName) {
      const [prefix, base64Data] = (base64String || '').split(',');
      const byteString = atob(base64Data);

      const byteNumbers = new Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteNumbers[i] = byteString.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const mimeType = prefix.match(/:(.*?);/)[1];

      const blob = new Blob([byteArray], { type: mimeType });

      return new File([blob], fileName, { type: mimeType });
    },

    createOperation() {
      mixpanel.track('click', {
        button_name: 'create_operation'
      });

      if (!this.$refs.formBankRate.validate()) {
        return;
      }

      this.operation.status = OPERATION_WORKFLOW.EM_ANALISE;

      this.saveOperation();

      switch (this.operation.exchangeType.id) {
        case OPERATION_TYPE.REMMITANCE.id:
          this.navigateToReportsRemittance();
          break;

        case OPERATION_TYPE.EXCHANGE.id:
          this.navigateToReportsExchange();
          break;

        default:
          this.navigateToReportsRemittance();
          break;
      }
    },

    cancelCreateOperation() {
      this.$refs.formBankRate.resetValidation();
      this.operation.locked = false;
      this.saveOperationModal = false;
    },

    createOpenOperation() {
      mixpanel.track('click', {
        button_name: 'create_open_operation'
      });

      if (!this.isFormValid()) {
        return;
      }

      this.copyCustomerMessageToClipboard();

      this.saveSketch();
    },

    callbackPrimary() {
      this.operation.exchangeType.id = undefined;
      this.openConfirmationWindow = false;
    },

    cancel() {
      if (this.operation.exchangeType.id) {
        this.openConfirmationWindow = true;
      } else {
        return;
      }
    },

    saveOperation() {
      let operationDocuments = this.operation.documents;

      this.$eventBus.$emit('show-loading', true);
      this.operationApi
        .add(this.operation)
        .then((response) => {
          this.sendMessage('Operação salva com sucesso!', 'success');
          this.saveDocuments(operationDocuments, response.data);
        })
        .catch((error) => {
          this.$eventBus.$emit('show-loading', false);
          console.log(error);
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.saveOperationModal = false;
        });
    },

    async saveSketch() {
      this.operation.documents = await Promise.all(
        this.operation.documents.map(async (item) => {
          item.document = await this.convertFileToBase64(item.document);

          return item;
        })
      );

      let sketch = new SketchModel({
        content: JSON.stringify(this.operation),
        typeId: TYPES.OPERATION.id,
        agentId: this.operation.agent?.id,
        customerId: this.operation.customer?.id,
        bankId: this.operation.bank?.id,
        currencyId: this.operation.currency?.id
      });

      if (!this.operation.sketchId) {
        this.createSketch(sketch);
        this.navigateToOperationsOpen();
      } else {
        this.updateSketch(sketch);
      }
    },

    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },

    createSketch(sketch) {
      this.$eventBus.$emit('show-loading', true);
      this.sketchApi
        .add(sketch)
        .then(() => {
          this.sendMessage('Pré-venda salva com sucesso!', 'success');

          /* Reloads the current view */
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    updateSketch(sketch) {
      this.$eventBus.$emit('show-loading', true);
      this.sketchApi
        .update(this.operation.sketchId, sketch)
        .then(() => {
          this.sendMessage('Pré-venda salva com sucesso!', 'success');
          this.$router.push({
            name: 'FormOperation'
          });
          /* Reloads the current view */
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    saveDocuments(operationDocuments, operation) {
      let documentPromisseList = [];

      operationDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append('operationId', operation.id);
        formData.append('idOperationDocumentType', document.operationDocumentType?.id);
        formData.append('document', document.document);

        documentPromisseList.push(
          this.operationDocumentApi.add(formData).then((response) => {
            return response.data;
          })
        );
      });

      Promise.all(documentPromisseList)
        .then((itens) => {
          let documentIds = itens.map((d) => d.id);
          let customerOperationalDocumentIds = this.operation.customerOperationalDocuments.map(
            (d) => d.id
          );

          let triggerPromisseList = [];

          if (this.operation.exchangeType.id == OPERATION_TYPE.REMMITANCE.id) {
            triggerPromisseList.push(
              this.operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds)
            );
            triggerPromisseList.push(this.operationIndicationAcknowledgementTrigger(operation));
          }

          Promise.all(triggerPromisseList).then(() => {
            if (this.operation.sketchId) {
              this.sketchApi.remove(this.operation.sketchId);
              this.$router.push({
                name: 'FormOperation'
              });
            }

            /* Reloads the current view */
            this.$router.go(0);
          });
        })
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        })
        .finally(() => {
          this.$eventBus.$emit('show-loading', false);
        });
    },

    copyCustomerMessageToClipboard() {
      navigator.clipboard.writeText(operationMessagesTemplate.getCustomerMessage(this.operation));

      this.sendMessage('A mensagem do cliente foi copiada em seu CTRL + C', 'success');
    },

    copyBankMessageToClipboard() {
      navigator.clipboard.writeText(operationMessagesTemplate.getBankMessage(this.operation));

      this.sendMessage('A mensagem do banco foi copiada em seu CTRL + C', 'success');
    },

    isFormValid() {
      this.reassignFormInputs(this.$refs.formOperation);
      this.$refs.formOperation.validate();

      if (!this.valid) {
        let invalidElement = this.$refs.formOperation.inputs.find((i) => i.valid == false);
        invalidElement.$el.scrollIntoView({ behavior: 'smooth', block: 'end' });

        return false;
      }

      return true;
    },

    openSaveOperationModal() {
      if (!this.isFormValid()) {
        return;
      }

      this.operation.locked = true;

      this.saveOperationModal = true;
    },

    editCustomer() {
      mixpanel.track('click', {
        button_name: 'edit_customer'
      });
      this.$router.push({
        name: 'FormEditCustomer',
        params: {
          id: this.operation.customer.id
        }
      });
    },

    mountOperationFromQueryString() {
      this.loading = true;
      this.operation.exchangeType.id = this.$route.query.idExchangeType;
      this.operation.customer.id = this.$route.query.idCustomer;
      this.operation.amount = this.$route.query.amount;
      this.operation.exchangeContractCost = this.$route.query.contractCost;
      this.operation.deliveryCost = this.$route.query.deliveryCost;
      this.operation.exchangeBuy = this.$route.query.exchangeBuy === 'true';
      this.$nextTick(() => {
        this.loading = false;
      });
    },

    resetOperation() {
      if (this.loading) {
        return;
      }

      this.operation = new OperationModel({
        exchangeBuy: this.operation.exchangeBuy,
        exchangeType: this.operation.exchangeType
      });
      this.panelKey++;
    },

    operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_FECHAMENTO,
        idOperation: operation.id,
        idCustomer: operation.customer.id,
        documentIds: documentIds,
        customerOperationalDocumentIds: customerOperationalDocumentIds
      });

      return this.triggerApi
        .trigger(trigger)
        .then(() => {})
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    operationIndicationAcknowledgementTrigger(operation) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_AGRADECIMENTO_INDICACAO,
        idOperation: operation.id
      });

      return this.triggerApi
        .trigger(trigger)
        .then(() => {})
        .catch((error) => {
          this.sendMessage((error.response && error.response.data.mensagem) || error, 'error');
        });
    },

    navigateToOperationsOpen() {
      this.$router.push({ name: 'PreSaleReport' });
    },

    navigateToReportsRemittance() {
      this.$router.push({ name: 'RemittanceOperationReport' });
    },

    navigateToReportsExchange() {
      this.$router.push({ name: 'ExchangeOperationReport' });
    }
  }
};
</script>
